

const Footer = () => {
  return (
    <>

      <footer className="mt-4 text-center">
    <div className="container">
      <div className="offset-lg-1 col-lg-10">
        <div className="row mb-3">
          <div className=" py-2">
            <h6>Disclaimer</h6>
            <p>We receive payments from the third-party solicitors to whom we refer claims.</p>
            <p>The solicitors we work with operate on a no-win-no-fee basis. Typically, customers pay 25% (inclusive of VAT) of 
              the compensation amount that is recovered, although this is subject to your individual circumstances and the actual 
              fee may be less than this. Should you terminate your agreement outside the 14-day cooling-off period, termination fees 
              may apply.</p>
              <p>Where you may have a criminal injury claim, you are not required to use the services of a claims management company to 
                pursue the claim. It is possible for you to present the claim yourself directly, for free, to the Criminal Injuries 
                Compensation Authority (CICA). You must first have obtained a crime reference number.</p>
                <p>Depending on your circumstances, you may be able to pursue a claim yourself, for free, without using the service of a 
                  claims management company, and without incurring a fee by claiming directly with the Motor Insurance Bureau. 
                  This may apply where you have been involved in an accident with a driver and intend to claim against an uninsured driver, 
                  an untraced driver, accidents in the UK involving a foreign-registered vehicle, or a claim involving a vehicle accident 
                  abroad (EEA/Green Card System).</p>
                  <p>Depending on your circumstances and the value of your claim, you may be able to pursue a claim yourself, 
                    for free, without using the services of a claims management company and without incurring a fee by claiming 
                    directly through the Official Injury Claim Portal.</p>
                    <p>The Compensation Experts is a trading name of National Injury Claimline Ltd. National Injury Claimline Ltd is 
                      authorised and regulated by the Financial Conduct Authority and is a claims management company. Firm Reference 
                      Number: 837834.</p>
          </div>
          <div>
            <h6>Useful Links</h6>
          </div>
          <div>
            <p><a className="pe-1" target="_blank" href="/privacy">Privacy Policy</a> |
              <a className="ps-1" target="_blank" href="/complaint">  Complaint Procedure</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
    </>
  )
}

export default Footer