import React,{ useEffect } from 'react';
import "../../../assets/UKPI_V1/css/personel.scss";

import FooterPolicy from "./FooterPolicy";

const Complaint = () => {
  useEffect(() => {
    document.title = 'Complaint Procedure';
    }, []);
  return (
    <>
   <div className="personal_injury" id="personal_injury">
 
  <header>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-12 text-start">
          <img className='compensation-logo' src="/assets/UKPI_V1/img/logo.png" alt="" />
        </div>
      </div>
    </div>
  </header>
  <section className="privacy">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-12">
          <h1>Complaints Procedure</h1>
          <p>We specialise in helping victims of injury claim the compensation they deserve</p>
          <h3>COMPLAINTS PROCEDURE</h3>
          <p>We always want to provide the best possible service for our customers but recognise that sometimes you might be disappointed with our service. If you are dissatisfied with the services that we have provided to you, you can make a complaint to us. By making a complaint, you’re giving us the opportunity to investigate and improve our services for everybody.</p>
          <p>To make a complaint, please contact us using the information provided in this procedure. We’ll then review your complaint and provide you with our response. Then, if you are not satisfied with the response we have provided, you can escalate the complaint to an Ombudsman for their decision.</p>
          <h3>HOW TO MAKE A COMPLAINT</h3>
          <p>If you wish to make a complaint about our services, you can do so by any reasonable means. To contact us, you can use the following details:</p>
          <p>By Phone: 0161 452 0311</p>
          <p>By Email: <a href="mailto:customerservice@the-compensation-experts.co.uk">customerservice @the-compensation- experts.co.uk</a></p>
          <p>By Mail or in Person: Craig House, 33 Ballbrook Avenue, West Didsbury, Manchester, M20 3JG.</p>
          <h3>INFORMATION TO PROVIDE WHEN MAKING A COMPLAINT</h3>
          <p>We aim to resolve your complaint as quickly as possible. To help us do this, please provide the following when making your complaint:</p>
          <ol>
            <li>A description of your concern</li>
            <li>What you would like us to do to put things right</li>
            <li>Your name and address</li>
            <li>A phone number and the best time to contact you</li>
          </ol>
          <h3>WHAT HAPPENS AFTER MAKING A COMPLAINT</h3>
          <h6>COMPLAINTS RESOLVED WITHIN 3 DAYS</h6>
          <p>If we can resolve your complaint within 3 business days from the date that we have received your complaint, we will write to you confirming that the complaint has been resolved and provide you with a Summary Resolution Communication. We will only consider your complaint resolved within this timeframe when you indicate acceptance of our response.</p>
          <h6>COMPLAINTS NOT RESOLVED WITHIN 3 DAYS</h6>
          <p>If we cannot resolve your complaint within three business days from the date it has been received, will issue a written acknowledgement letter to you promptly and no later than 3-5 working days following the date we received your complaint. Our acknowledgement letter will provide you with the early assurance that we have received the complaint and that we are dealing with it. Following the issuance of the acknowledgement letter, we will keep you informed thereafter regarding the progress of the measures that are being taken to resolve the complaint.</p>
          <p>Our Complaint Handler will then review your complaint and may need to contact you to seek additional information or clarifications to ascertain the facts surrounding your complaint. Your concerns will then be fully investigated by our complaint handler.</p>
          <p>Following our investigations, we will issue you a final response letter within 8 weeks of the date that we received your complaint. The final response will detail our findings and determination. Where we are unable to provide you with a final response within the 8 weeks following the date that we received your complaint, we will issue you with a written response which will explain why we are not yet in a position to provide you with a final response, when you can expect to receive a final response and explain that you may refer the complaint to the Financial Ombudsman Service (specifically the Claims Management Ombudsman, a division within the Financial Ombudsman Service).</p>
          <h3>THE FINANCIAL OMBUDSMAN SERVICE (CLAIMS MANAGEMENT OMBUDSMAN)</h3>
          <p>If you are not satisfied with our resolution of your complaint i.e., where you receive a Summary Resolution Communication, final response, or you have received a written response and have not received a final response within the 8-week timeframe, you can escalate your complaint to the Financial Ombudsman Service, specifically the  Claims Management Ombudsman (which is part of the Financial Ombudsman Service) at:</p>
          <p>Website | https:// cmc.financial -ombudsman. org.uk/</p>
          <p>Address | Financial Ombudsman Service, Exchange Tower, London, E14 9SR</p>
          <p>Freephone | 0800 0234 567</p>
          <p>Standard Telephone | 0300 1239 123</p>
          <p>Outside of the UK | +44 20 7954 0500</p>
          <p>If you wish to raise your complaint to the Ombudsman, you must do this within six months from the date of our final response unless we have expressly said otherwise within our final response. If you do not refer your complaint in time, the Ombudsman will not have our permission to consider your complaint and so will only be able to do so in very limited circumstances. For example, if the Ombudsman believes that the delay was a result of exceptional circumstances. Their contact information is as follows:</p>
          <p>Financial Ombudsman Service’s Explanatory Leaflet: https://www.financial-ombudsman.org.uk/businesses/resolving-complaint/ordering-leaflet/leaflet</p>
          <p>You can find more information on the services provided by the Financial Ombudsman Service by visiting their website at  https://www.financial-ombudsman.org.uk/</p>
        </div>
      </div>
    </div>
  </section>
    <FooterPolicy/>
   
 
</div>

    </>
  )
}

export default Complaint;