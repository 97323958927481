import { useState, useEffect } from "react";
import { useRingbaInfo } from "../Hooks/useRingbaInfo";

const RingbaApi = (campaign_name) => {

  const [response, setResponse] = useState("");
  const { postRingbaInfo } = useRingbaInfo();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await postRingbaInfo(campaign_name);
        setResponse(data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return { response }
}

export default RingbaApi;