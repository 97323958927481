import React from 'react';
const TrustBox = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-style-height="40px" data-businessunit-id="5e96cbce95084f0001850cd1" data-style-width="100%" data-theme="light" data-stars="4,5"

    >
      <a href="https://www.trustpilot.com/review/the-compensation-experts.co.uk" target="_blank" rel="noopener"> Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;