export const question = [{
    id: 2,
    question: ["Were you injured in the last 3 years?"],
    options: [
      {
        text: "Yes",
        id: "8"
      },
      {
        text: "No",
        id: "9",
  
      }
    ]
    },
    {
    id: 8,
    question: ["Are you already working with a solicitor?"],
    options: [
        {
            text: "Yes",
            id: "25"
          },
          {
            text: "No",
            id: "26",
      
          }
    ]
  },
   {
    id: 1,
    final_question:true,
    question: ["What type of accident was it?"],
    options: [
      {
        text: "Please Select",
        id: ""
      },
      {
        text: "Accident At Work",
        id: "1"
      },
      {
        text: "Accident in a private place(shop, bar, restaurant etc.)",
        id: "2"
      },
      {
        text: "Accident in a public place (park, pavement etc.)",
        id: "3"
      },
      {
        text: "Road Traffic Accident",
        id: "4"
      },
      {
        text: "Industrial Illness",
        id: "5"
      },
      {
        text: "Criminal Injury",
        id: "6"
      },
      {
          text:"Other",
          id:"7"
      }
    ]
  }];
  
  