import React, { useState, useContext } from "react";
import RadioButton from "../../../UI/RadioButton";
import TrustBox from "../../../UI/TrustBox";
import star from "../../../../assets/UKPI_V5/img/stars-4.5.svg";
import info from "../../../../assets/UKPI_V5/img/info.jpg";
import Popup from "./Popup";
import { useVisitorAnswer } from "../../../../Hooks/useVisitorAnswer";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
// import arrow from "../../../../assets/UKPI_V5/img/arrow.png";

const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active12, setActive12] = useState("");
  const [active13, setActive13] = useState("");
  const [shwPopup, setshwPopup] = useState(false);
  const { saveAnswer } = useVisitorAnswer();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const nextSlide = async (e) => {
    if (e.target.value == 12) {
      setActive12("active");
    } else {
      setActive12("");
    }

    if (e.target.value == 13) {
      const saveAns = await saveAnswer(
        2,
        e.target.value,
        visitorParameters.visitor_parameters
      );

      setActive13("active");
      // setshwPopup(true);
      // return false;
    } else {
      setActive13("");
    }
    slideChange(e);
  };

  return (
    <>
      <Popup
        show={shwPopup}
        setshwPopup={setshwPopup}
        message="Sorry, If you are not injured in the last 3 years, we can't help you."
        modalclassName="custom-modal"
      />
      <div id="slide1" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center p-3 pb-0 firstslide">
          <div className="">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>
            <RadioButton
              value="12"
              name="question_4"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Yes"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active12}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="13"
              name="question_4"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="No"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold mt-3 nopad ${active13}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a name="question_2" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/UKPI_V2/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>

        <div className=" col-lg-12 col-12 trust_pi mb-2">
          <a
            href="https://www.trustpilot.com/review/the-compensation-experts.co.uk"
            target="_blank"
          >
            <div className="trust-sec-widget">
              <TrustBox />
            </div>

            <div className="row box-a mds-4 ">
              <div className="col-7 p-0 trustpilot">
                <img src={star} className="img-star" alt="" />
              </div>
              <div className="col-3 p-0 d-flex flex-row align-items-center">
                <div>
                  <p>
                    <b className="rate-num px-2"> 4.6</b>
                  </p>
                </div>
                <div className="last-trust-img">
                  <img
                    src={info}
                    className="info_img"
                    alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                                            Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                  />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;
