require("dotenv").config();
const AppDomain = window.location.origin;
const AppTitle = process.env.REACT_APP_TITLE;
const AppEnv = process.env.REACT_APP_ENV;
const AppDomainWithoutProtocol = window.location.hostname;
const AppLpDefaultUrl = process.env.REACT_APP_LP_DEFAULT_URL;
const AppAdtopiaUPID = process.env.REACT_APP_ADTOPIA_UPID;
const AppReferrerURL = document.referrer;
const AppLpRedirectDefaultUrl = process.env.REACT_APP_LP_REDIRECT_DEFAULT_URL;
const AppApDefaultUrl = process.env.REACT_APP_AP_DEFAULT_URL;
const AppEmailValidateSingleApi = process.env.REACT_APP_EMAIL_VALIDATE_API;
const AppEmailWebhookApi = process.env.REACT_APP_EMAIL_VALIDATE_WEBHOOK_API;
const AppAdtopiaToken = process.env.REACT_APP_ADTOPIA_TOKEN;
const AppGmailWebhookApi = process.env.REACT_APP_GMAIL_WEBHOOK_API;
const AppGmailWebhookError = process.env.REACT_APP_GMAIL_WEBHOOK_ERROR;
const AppSentryWebhookError = process.env.REACT_APP_SENTRY_WEBHOOK_ERROR;
console.log("AppDomain WithoutProtocol  : = " + AppDomainWithoutProtocol);

export {
  AppDomain,
  AppTitle,
  AppEnv,
  AppDomainWithoutProtocol,
  AppLpDefaultUrl,
  AppAdtopiaUPID,
  AppReferrerURL,
  AppLpRedirectDefaultUrl,
  AppApDefaultUrl,
  AppEmailValidateSingleApi,
  AppAdtopiaToken,
  AppEmailWebhookApi,
  AppGmailWebhookApi,
  AppGmailWebhookError,
  AppSentryWebhookError
};