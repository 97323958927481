import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import TrustBox from "../../../UI/TrustBox";
import star from "../../../../assets/UKPI_V4/img/stars-4.5.svg";
import info from "../../../../assets/UKPI_V4/img/info.jpg";

const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active1, setActive1] = useState("");
  const [active2, setActive2] = useState("");
  const [active3, setActive3] = useState("");
  const [active4, setActive4] = useState("");
  const [active5, setActive5] = useState("");
  const [active6, setActive6] = useState("");
  const [active7, setActive7] = useState("");
  const [active23, setActive23] = useState("");
  const [active24, setActive24] = useState("");
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [modalClass1, SetModalClass1] = useState("hide");
  const [modalClass2, SetModalClass2] = useState("hide");

  const nextSlide = async (e) => {
    if (e.target.value == 1) {
      setActive1("active");
    } else {
      setActive1("");
    }

    if (e.target.value == 2) {
      setActive2("active");
    } else {
      setActive2("");
    }

    if (e.target.value == 3) {
      setActive3("active");
    } else {
      setActive3("");
    }

    if (e.target.value == 4) {
      setActive4("active");
    } else {
      setActive4("");
    }

    if (e.target.value == 5) {
      setActive5("active");
    } else {
      setActive5("");
    }

    if (e.target.value == 6) {
      setActive6("active");
    } else {
      setActive6("");
    }

    if (e.target.value == 7) {
      setActive7("active");
    } else {
      setActive7("");
    }

    if (e.target.value == 23) {
      setActive23("active");
    } else {
      setActive23("");
    }
    if (e.target.value == 24) {
      setActive24("active");
    } else {
      setActive24("");
    }

    setShow(true);
    // setTimeout(() => {
    //   setShow(false);
    //   slideChange(e);
    // }, 3000);

    setTimeout(() => {
      setShow(false);
      SetModalClass1("show");
      setShowTwo(true);

      setTimeout(() => { UpdateSlide1() }, 2000);
      setTimeout(() => { UpdateSlide2() }, 3500);
      const UpdateSlide1 = () => {
        SetModalClass2("show");        
      }
      const UpdateSlide2 = () => {
        document.getElementById("analayzeModal").classList.remove('show');
        document.getElementById("analayzeModal").classList.add('hide');
        document.body.classList.remove("modal-open");
        SetModalClass1("hide")
        SetModalClass2("hide")
        setShowTwo(false);
        slideChange(e);
      }
    }, 2500);
  };
  return (
    <>
      <div id="slide7" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center p-3 firstslide">
          <div className="mb-3">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>
            <h5>This includes any minor accident that resulted in a physical injury</h5>
            <RadioButton
              value="1"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Accident At Work"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active1}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="23"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Medical Procedure"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active23}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="24"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Slip Trip or Fall"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active24}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="4"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Road Traffic Accident"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active4}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="2"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Accident in a private place (shop, bar, restaurant etc.)"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active2}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="3"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Accident in a public place (park, pavement etc.)"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active3}`}
              onClick={nextSlide}
              validation={validation()}
            />

            

            <RadioButton
              value="5"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Industrial illness"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active5}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="6"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Criminal Injury"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad mb-3 ${active6}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="7"
              name="question_1"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Other"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active7}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a name="question_2" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/UKPI_V4/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text">
                <img
                  src="assets/UKPI_V4/img/ico-safe.png"
                  className="safe-text"
                  alt=""
                />{" "}
                Safe | Secure | Privacy Protected
              </div>
              
              <div className=" col-lg-12 col-12 trust_pi mb-2 mt-3">
                    <a
                      href="https://www.trustpilot.com/review/the-compensation-experts.co.uk"
                      target="_blank"
                    >
                      <div
                        className="trust-sec-widget"
                      >
                        <TrustBox />
                      </div>
        

                      <div className="row box-a mds-4 "> 
                         <div className="col-7 p-0 trustpilot"> 
                         <img src={star} className="img-star" alt="" />
                          </div>
                         <div className="col-3 p-0 d-flex flex-row align-items-center"> 
                         <div> 
                         <p> 
                          <b className="rate-num px-2"> 4.6</b>
                          </p>
                         </div>
                          <div className="last-trust-img"> 
                          <img
                          src={info}
                          className="info_img"
                          alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                                            Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                        />
                          </div>
                         </div>
                      </div>
                    </a>
                  </div>
      </div>        

      <Modal size="lg" className="custom_modal cstm_mdl fade text-center " show={show} centered contentClassName="nobg">
        <Modal.Body className="bgno">
          <img src="assets/UKPI_V4/img/review1.gif" />
        </Modal.Body>
      </Modal>

      <Modal size="lg" className="custom_modal cstm_mdl text-center" show={showTwo} centered id="analayzeModal">
        <Modal.Body>
          <div className="py-5">
            <div id="slide6nxt" className={`col-12 slide-pad  text-center ${modalClass1}`} >
              <h2>Good News!</h2>
            </div>  
            <div id="slide62nxt" className={`col-12 slide-pad text-center slide6nxt animated fadeInUp ${modalClass2}`} >
                <h2>It appears you may have a successful compensation claim.</h2>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BasicQuestionnaireOne;
