import React from "react";
import InputButton from "../../../UI/InputButton";

const AgreementSection = () => {

  const scroll =  () => {
    document.getElementById("div_form").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }
  return (
    <>
      <section className="agreement_section text-white py-4">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-12">
              <h3 className="text-center mb-3">No Win, No Fee Agreement</h3>
              <p>
                {/* <i>
                  The solicitors we work with operate on a no-win-no-fee basis.
                  Typically, customers pay 25% (inclusive of VAT) of the
                  compensation amount that is recovered, although this is
                  subject to your individual circumstances and the actual fee
                  may be less than this. Should you terminate your agreement
                  outside the 14-day cooling-off period, termination fees may
                  apply.{" "}
                </i> */}
                <i>
                The solicitors we work with operate on a no-win-no-fee basis. Typically, customers pay 25% (inclusive of VAT) of the compensation amount that is recovered, although this is subject to your individual circumstances and the actual fee may be less than this. Should you terminate your agreement outside the 14-day cooling-off period, termination fees may apply.{" "}
                </i>
              </p>
              <div className="mb-3 text-center">

              <InputButton
            name="formSubmitBtn"
            className="btn claim_btn text-white fw-bold rounded-pill fs-4 mt-3 p-3"
            value="Start Your Claim"
            btnType="button"
            onClick={scroll}
          />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgreementSection;
