import { Helmet } from "react-helmet";

const Header = () =>{
    return(
        <>
        {/* <Helmet>
          <script src="//b-js.ringba.com/CAe2cad3c1157d424c9be5d56134af617b" async></script>
        </Helmet> */}
        <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>
            <header>
            <h1 className="mb-2">
              Speak to our experts for assistance in maximizing your potential
              compensation
            </h1>
            {/* <p>( ¿Si tiene Tarjeta Verde, es Ciudadano, es Residente o tiene Permiso de Trabajo? )</p> */}
          </header>
        </>
    )
}
export default Header;