
const OfflineEnglishButtonLink = (redirect) => {
  let redirectUrl = redirect;

  const urlParams = new URLSearchParams(window.location.search);
  const s2 = (urlParams.get('atp_source')) ? urlParams.get('atp_source') : '';
  const s3 = (urlParams.get('facebook_cid')) ? urlParams.get('facebook_cid') : '';
  const s4 = (urlParams.get('crvid')) ? urlParams.get('crvid') : '';
  const s5 = (urlParams.get('uuid')) ? urlParams.get('uuid') : '';
  const s6 = (urlParams.get('atp_vendor')) ? urlParams.get('atp_vendor') : '';

  const mapObj = {
    '{atp_source}': s2,
    '{facebook_cid}': s3,
    '{crvid}': s4,
    '{uuid}': s5,
    '{atp_vendor}': s6,
  };

  //   redirectUrl = redirectUrl.replace(/[{atp_source}|{facebook_cid}|{crvid}|{uuid}]/g, m => chars[m]);
  redirectUrl = redirectUrl.replace(/{atp_source}|{facebook_cid}|{crvid}|{uuid}|{atp_vendor}/gi, function (matched) {
    return mapObj[matched];
  });

  const buttonRedirectionLink = redirectUrl;
  return { buttonRedirectionLink }
}

export default OfflineEnglishButtonLink;
