import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header className='p-2 bg-white'>
         <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <img src="assets/UKPI_V3/img/logo1.jpg" alt="" className="logo" />     
              </div>
            </div>
          </div>
      </header>
    </>
  );
};

export default Header;