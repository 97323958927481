const GtmDataLayer = ({
  question,
  answer,
  conversionValue = null,
  optionValue = null,
  injuryType = null,
}) => {
  if (answer) {
    window.dataLayer = window.dataLayer || [];

    const data = {
      event: "slideclick",
      question: question,
      answer: answer,
    };

    conversionValue =
      conversionValue ?? localStorage.getItem("conversionValue");
    injuryType = injuryType ?? localStorage.getItem("injuryType");

    if (conversionValue !== null) {
      data.conversionvalue = conversionValue;
    }
    if (injuryType !== null) {
      data.injurytype = injuryType;
    }
    if (optionValue !== null) {
      data.optionvalue = optionValue;
    }

    console.log("GtmDataLayer: ", data);

    window.dataLayer.push(data);
  }

  return <></>;
};

export default GtmDataLayer;
