import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useVisitorAnswer = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveAnswer = async (questionaire_id, answer_id, visitor_data) => {
    const response = await Api.post("v1/visitor-answer", {
      questionaire_id,
      answer_id,
      visitor_data,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'VISITOR ANSWER',
        errorReason: error.message,
        errorCode: error.code,
        uuid:visitor_data.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { saveAnswer };
};
