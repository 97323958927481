import React from "react";

const CheckBoxV2 = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  validation,
  checked
}) => {
  return (
    <>
        <input
          type="checkbox"
          className={className}
          name={name}
          id={id}
          value={value}
          onClick={onClick}
          ref={validation}
          checked={checked}
          data-value={labelName}
        />
        <label htmlFor={id} className={labelClassName}>
        {labelName}
      </label>
    </>
  );
};
export default CheckBoxV2;
