import { useContext, useEffect, useState } from "react";
import "../../../assets/UKPI_V7/css/style.scss";
import Footer from "../../Includes/Layouts/UKPI_V7/Footer";
import FormUKPI_V7 from "../../Forms/FormUKPI_V7";
import vido from "../../../assets/UKPI_V7/img/12.png";
import Vdo_1 from "../../../assets/UKPI_V7/img/Vdo_1.mp4";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useUserClicks } from "../../../Hooks/useUserClicks";
import { UUID } from "../../../Utility/UUID";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import SimpleModal from "../../Includes/Layouts/UKPI_V7/SimpleModal";
import RingbaApi from "../../../Utility/RingbaApi";
import RingbaPhoneNumberEnglish from "../../../Utility/RingbaPhoneNumberEnglish";
import OfflineEnglishButtonLink from "../../../Utility/OfflineEnglishButtonLink";
import Header from "../../Includes/Layouts/UKPI_V7/Header";



const UKPI_V7 = () => {
  const campaign_name = "english";

  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const [buttonRedirect, setButtonRedirect] = useState();
  const { response } = RingbaApi(campaign_name);
  let is_online = response.is_online;
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineEnglishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberEnglish(utmName);

  const [muted, setMuted] = useState(true);
  const splitName = "UKPI_V7";
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
 
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [modalBody, setModalBody] = useState("");

  const formSubmit = async (values) => {
    const queryString = "";
    values.lstSalutation = "";
    values.txtFName = "";
    values.txtLName = "";
    values.txtEmail = "";
    values.txtPhone = "";
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      "split_form_submit",
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(
      uuid,
      slide_type,
      slide_question,
      slide_value,
      type,
      splitName
    );
  };

  return (
    <>
      <AdtopiaLoadLP PageType="LP" splitName={splitName} />
      <FbViewPixel />
      <div className="UKPI_V7">
        <div className="wrap-content">
          <Header/>
          <a type="hidden" href={`tel: ${phoneNumber}`}></a>
          <div id="videoDiv" className="video-post">
            <div
              className="volume"
              id="muteBtn "
              onClick={() => setMuted(!muted)}
            >
              <i
                className={`bi bi-volume-${
                  muted ? "down" : "mute"
                }-fill vlicon`}
              />{" "}
            </div>
            <video
              poster={vido}
              id="mVideo"
              autoPlay
              muted={muted === true ? true : false}
              loop
              onClick={() => setMuted(!muted)}
            >
              <source src={Vdo_1} type="video/mp4" />
            </video>
          </div>

          <FormUKPI_V7
            splitName={splitName}
            formSubmit={formSubmit}
            journeyStatus={journeyStatus}
            saveUser={saveUser}
            uuid={uuid}
            is_online={is_online}
            modalBody
            buttonRedirect={buttonRedirect}
            phoneNumber={phoneNumber}
            formattedPhoneNumber={formattedPhoneNumber}
            setModalBody={setModalBody}
          />
        </div>
      </div>
      <Footer />

      {/* Modal */}

      <SimpleModal modalBody={modalBody} setModalBody={setModalBody} />

      {/* Modal End*/}
    </>
  );
};
export default UKPI_V7;
