import React, { useState, useContext } from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { currentdate, currenttime } from "../../../../Constants/Constants";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
//import { useEmailWebhook } from "../../../../Hooks/useEmailWebhook";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import TextField from "../../../UI/TextField";
const axios = require(`axios`);

const ContactDetails = ({
  className,
  validation,
  validationMsg,
  trigger,
  slideChange,
  prevSlide,
  textHeading,
  getValues,
  setError,
  formSubmit,
}) => {
  const { emailValidation, emailValidationErr } = useEmailValidation();
  //const { weHookApiCall } = useEmailWebhook();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [disabled, setDisabled] = useState(false);
  const [loader, setloader] = useState(false);
  //const [webHookState, setWebHookState] = useState(false);

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone,visitorParameters.visitor_parameters.uuid);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Mobile Number",
        });
        return 0;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );

      if (emailValidationErr) {
        return 1;
      }
      if (
        typeof getEmailValidation === "undefined" ||
        getEmailValidation === null
      ) {
        return 2;
      }

      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const validNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    else {
      return true;
    }
  };

  const submitForm = async (e) => {

    setloader('loader_img')
    const formvalues = getValues();
    const emailValidChk = await trigger("txtEmail");
    const phoneValidChk = await trigger("txtPhone");
    const FnameValidChk = await trigger("txtFName");
    const LnameValidChk = await trigger("txtLName");

    if (emailValidChk && phoneValidChk && FnameValidChk && LnameValidChk) {

      if ((await emailValidCheck()) === 2 || emailValidationErr) {

        // const WEBHOOK_URL = 'https://chat.googleapis.com/v1/spaces/AAAAtrJLGcM/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=f4LHleR2F_Z-lM53d9RcVf8BuqBj5dgQhLj_RP-74ho';
        // if(!webHookState) {
        // const message = `
        // {
        //   Service Name : Email Single Endpoint API
        //   Project ID : NCPI036
        //   UUID : ${visitorParameters.visitor_parameters.uuid}
        //   Error Reason : Validation API Timeout
        //   Datetime : ${currentdate} ${currenttime}
        // }
        // `;
        // const getWebhookRes = await weHookApiCall(message);
        // console.log('api delay');
        // }
        // setWebHookState(true);

        let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!formvalues["txtEmail"].match(mailformat)) {
          setError("txtEmail", {
            type: "manual",
            message: "Please enter valid Email Address",
            shouldFocus: true,
          });
          return false;
        }

        setDisabled(true);

      }


      if (((await emailValidCheck()) === 1 || (await emailValidCheck()) === 2) && (await phoneValidCheck()) === 1) {
        console.log('form submitted');
        formSubmit();
      } else {
        setDisabled(false);
        setloader(false);
      }
    } else {
      setDisabled(false);
      setloader(false);
    }
  };
  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  return (
    <>
      <div id="slide9" className={className}>
        <div className="form_cont col-lg-12 col-md-12 col-sm-12 text-center  mt-1 dmy p-3 pb-0">
          <p className="mb-0  fw-bold qstn_1">{textHeading}</p>
          <div className="input-group mb-3">

            <TextField
              type="text"
              className="form-control p-3"
              placeholder="First Name"
              onBlur={namevalidate}
              name="txtFName"
              id="txtFName"
              validation={validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtFName && validationMsg.txtFName.message
              }
            ></TextField>
          </div>

          <div className="input-group mb-3">
            <TextField
              type="text"
              className="form-control p-3"
              placeholder="Last Name"
              onBlur={namevalidate}
              name="txtLName"
              id="txtLName"
              validation={validation({
                required: "Please Enter Surname",
                minLength: {
                  value: 2,
                  message: "Please Enter Valid Surname",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Surname is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtLName && validationMsg.txtLName.message
              }
            ></TextField>
          </div>

          <div className="input-group mb-3">
            <Telephone
              name="txtPhone"
              className="form-control p-3"
              placeholder="Phone Number"
              onBlur={phoneValidCheck}
              onKeyPress={validNumber}
              validation={validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Valid Phone Number",
              })}
            // validationMsg={
            //   validationMsg.txtPhone && validationMsg.txtPhone.message
            // }
            ></Telephone>

            <div className="input-group-append">
              <span className={"form-control p-3"}>
                <img
                  src="/assets/UKPI_V2/img/privacy.png"
                  alt=""
                  className="privcy pull-right"
                />
              </span>
            </div>
            {validationMsg.txtPhone && (
              <span className="error_msg " id="phone_err">
                {validationMsg.txtPhone.message}
              </span>
            )}
          </div>

          <div className="input-group mb-3">

            <Email
              name="txtEmail"
              className="form-control p-3"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please Enter Valid Email Address",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                //   message: "Invalid Email Address",
                // },
              })}
            // validationMsg={
            //   validationMsg.txtEmail && validationMsg.txtEmail.message
            // }
            ></Email>
            <i className="tick fa_privcy" style={{ display: "none" }}></i>
            <div className={"input-group-append"}>
              <span className={"form-control p-3"}>
                <img
                  src="/assets/UKPI_V2/img/privacy.png"
                  alt=""
                  className={"privcy pull-right"}
                />
              </span>
            </div>
            {validationMsg.txtEmail && (
              <span className="error_msg " id="email_err">
                {validationMsg.txtEmail.message}
              </span>
            )}
          </div>
          {/* <InputButton
            name="formSubmitBtn"
            className={`next-but btn my-bt sctop1 text-white fw-bold rounded ${loader}`}
            value="Get My Free Quote >>"
            btnType="button"
            onClick={submitForm}
            disabled={disabled}
          /> */}
          <InputButton
            name="formSubmitBtn"
            className={`next-but btn my-bt sctop1 text-white fw-bold rounded mb-2 ${loader}`}
            value="Reveal >"
            btnType="button"
            onClick={submitForm}
            disabled={disabled}
          />
          <a name="question_6" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/UKPI_V2/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>

        <div className="form-check p-2 offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 pt-0 text-center">
          <label
            className="form-check-label text-consent text-black fs-6 mt-0"
            htmlFor="accept"
          >
            I consent for National Injury Claimline Ltd (The Compensation
            Experts) to process the above data and (personal injury)
            health data to contact me in relation to a possible claim. To
            read more about how we process your personal data, please see
                  our{" "}
            <a href="/privacy" target="_blank">
              {" "}
                    Privacy Policy.
                  </a>
          </label>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;
