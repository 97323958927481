import React from "react";

const RadioButton = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  validation,
  checked
}) => {
  return (
    <>
      <label htmlFor={id} className={labelClassName}>
        <input
          type="radio"
          className={className}
          name={name}
          id={id}
          value={value}
          onClick={onClick}
          ref={validation}
          checked={checked}
          data-value={labelName}
        />
        {labelName}
      </label>
    </>
  );
};
export default RadioButton;
