import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Popup = ({ show, message, setshwPopup }) => {
  const handleClose = () => setshwPopup(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered backdrop={"static"}>
        <Modal.Body className="text-center pop-text"><p className="">{message}</p></Modal.Body>
        <Modal.Footer  className="justify-content-center p-0 bord-b">
          <Button className="custom-mdal-btn" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Popup;
