import { useState, useEffect, useRef } from "react";
import { question } from "../../Constants/ChatbotConstants/UKPI_V7constant";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import useJourneyStats from "../../Hooks/useJourneyStats";
import trustp from "../../assets/UKPI_V7/img/trustp.png";
import info from "../../assets/UKPI_V7/img/info.jpg";
import stars45 from "../../assets/UKPI_V7/img/stars-4.5.svg";
import loaderreview2 from "../../assets/UKPI_V7/img/loader-review2.svg";
import { useForm } from "react-hook-form";

const FormUKPI_V7 = ({
  formSubmit,
  journeyStatus,
  saveUser,
  uuid,
  formattedPhoneNumber,
  phoneNumber,
  buttonRedirect,
  is_online,
  splitName,
  setModalBody,
}) => {
  const { register, errors, clearErrors, setError, trigger, getValues } =
    useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSlide1, setShowSlide1] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const { saveDataIngestion } = useDataIngestion();
  const [selectedOption, setSelectedOption] = useState("");
  const saveJournyStats = useJourneyStats();
  const autofocus = useRef();
  const mySelect = useRef();

  useEffect(()=>{
  },[buttonRedirect,phoneNumber,formattedPhoneNumber])

  const questions = question;

  const handleCallBtnClick = async () => {
    setShowSlide1(false);
    setShowLoader(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setShowLoader(false);
    setShowQuestion(true);

    await saveJournyStats(uuid, "question", "2", "", "view", splitName);
  };

  useEffect(() => {
    if (
      questions &&
      questions.length > 0 &&
      currentQuestionIndex >= 0 &&
      currentQuestionIndex < questions.length
    ) {
      const selectedQuestion = questions[currentQuestionIndex];
      const questionId = selectedQuestion.id;
      if (questionId != "2" && selectedOption != "stop") {
        saveJournyStats(uuid, "question", questionId, "", "view", splitName);
      }
    }
  }, [questions, currentQuestionIndex, saveJournyStats, uuid, splitName]);

  useEffect(() => {
    localStorage.removeItem("questionData");
  }, []);

  const handleOptionClick = (option, submit) => {
    clearErrors();
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = option;
    if (questions[currentQuestionIndex].final_question == true) {
      setCurrentQuestionIndex(currentQuestionIndex);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (optionId) {
      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });
    }

    if (submit == "stop") setSelectedOption(submit);

    if (questionId === 2 && optionId === "9") {
      setCurrentQuestionIndex(currentQuestionIndex);
      setModalBody(
        "Sorry, If you are not injured in the last 3 years, we can't help you."
      );
    }
    if (questionId === 8 && optionId === "25") {
      setCurrentQuestionIndex(currentQuestionIndex);
      setModalBody(
        "Sorry, If you have received solicitor assistance, we can't help you"
      );
    }

    var existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (existingEntries == null) existingEntries = {};
    var newEntries =
      existingEntries == null ? (existingEntries = {}) : existingEntries;
    Object.assign(newEntries, {
      ["question_" + questionId]: optionId,
    });
    localStorage.setItem("questionData", JSON.stringify(newEntries));
    if (optionId != "9" && optionId != "25") {
      setAnsweredQuestions([...answeredQuestions, questionId]);
    }
  };

  const toggleFocus = (event) => {
    const scroll = window.screen.availWidth < 768 ? 110 : 200;

    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -200;

      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scroll(0, scroll);
    } else {
      window.scroll(0, scroll);
    }
  };

  const handleOptionClickBtn = (e, submit) => {
    const questionData = JSON.parse(localStorage.getItem("questionData"));

    let selectedVal = mySelect.current.value;
    let isValid = true;

    if (selectedVal === "") {
      setError("Status", {
        type: "required",
        message: "Please select a value.",
      });
      isValid = false;
    } else {
      clearErrors("Status");
      formSubmit(questionData);
      journeyStatus({
        slide_type: "button",
        slide_question: "CALL_CLICK",
        slide_value: "",
        type: "click",
      });
      saveUser("CALL_CLICK", 1);
    }

    // Conditionally prevent default based on the form's validity
    if (!isValid) {
      e.preventDefault();
    }
  };
  const handleRedirection = (e) =>{
    const questionData = JSON.parse(localStorage.getItem("questionData"));

    let selectedVal = mySelect.current.value;
    let isValid = true;

    if (selectedVal === "") {
      setError("Status", {
        type: "required",
        message: "Please select a value.",
      });
      isValid = false;
    } else {
      clearErrors("Status");
      formSubmit(questionData);
      journeyStatus({
        slide_type: "button",
        slide_question: "CALL_CLICK",
        slide_value: "",
        type: "click",
      });
      saveUser("CALL_CLICK", 1);
    }
    // Conditionally prevent default based on the form's validity
    if (!isValid) {
      e.preventDefault();
    }
    
    window.location.href = buttonRedirect.buttonRedirectionLink;
  }
  
  const atagClick = (e) =>{
    e.preventDefault();
  }

  return (
    <>
      <div className="bottm-video-content py-3 py-lg-4 py-md-4 text-center">
        {showSlide1 && (
          <div className="qs-slide1 qs-slide ">
            <div className="mb-3">
              {is_online == "offline" ? 
              (<button
                className="call-btn qs-next1"
                onClick={handleCallBtnClick}
              >
                Claim Benefits
              </button>)
              :
              (<button
              className="call-btn qs-next1"
              onClick={handleCallBtnClick}
            >
              Call Now
            </button>)
              }
            </div>

            <h5><a href={`tel:${phoneNumber}`} onClick={(e) => {e.preventDefault();}}>{formattedPhoneNumber}</a> </h5>
           
            <div className=" col-lg-12 col-12 trust_pi mb-2">
              <a
                href="https://www.trustpilot.com/review/the-compensation-experts.co.uk"
                target="_blank"
              >
                <div className="trust-sec-widget">
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="5419b6a8b0d04a076446a9ad"
                    data-style-height="40px"
                    data-businessunit-id="5e96cbce95084f0001850cd1"
                    data-style-width="100%"
                    data-theme="light"
                    data-stars="4,5"
                    style={{ position: "relative" }}
                  >
                    <p className="mb-0">
                      See our <b>812</b> reviews on{" "}
                      <img src={trustp} className="trustpi" alt="" title="" />
                    </p>
                  </div>
                </div>
                <div className="row box-a mds-4 ">
                  <div className="col-7 p-0 trustpilot">
                    <img src={stars45} className="img-star" alt="" />
                  </div>
                  <div className="col-3 p-0 d-flex flex-row align-items-center">
                    <div className="icon_sec">
                      <p>
                        <b className="rate-num"> 4.6</b>
                      </p>
                    </div>
                    <div className="last-trust-img">
                      <img src={info} className="" alt="" />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )}
        {/* Slide */}
        {showLoader && (
          <div className="qs-slide2 qs-slide">
            <div className="qs-inner py-lg-4 py-md-4 py-sm-2">
              <div className="qs-loader">
                <img src={loaderreview2} alt="" />
                <h2 className="qs-text mt-3">Please wait...</h2>
              </div>
            </div>
          </div>
        )}

        {showQuestion && questions[currentQuestionIndex] && !showSlide1 && (
          <div className="qs-slide4 qs-slide slide-top">
            <div className="qs-inner py-lg-4 py-md-4 py-sm-2">
              <ul className="qs-status">
                {questions.map((question, index) => (
                  <li key={index}>
                    <span
                      className={
                        answeredQuestions.includes(question.id) ? "active" : ""
                      }
                    ></span>
                  </li>
                ))}
              </ul>
              <h2 className="qs-text">
                {questions[currentQuestionIndex].question}
              </h2>
              <div className="row justify-content-center mt-4">
                <div className="col-lg-8 offset-lg-2">
                  <div className="row">
                    {questions[currentQuestionIndex].id == 1 && (
                      <div className="col-12 mb-3">
                        <select
                          id="Status"
                          className="form-style form-select form-control custom-select"
                          fdprocessedid="ddd"
                          onChange={(e) =>
                            handleOptionClick(e.target.value, "stop")
                          }
                          ref={mySelect}
                        >
                          {questions[currentQuestionIndex].options.map(
                            (option, index) => (
                              <option key={index} value={option.id}>
                                {option.text}
                              </option>
                            )
                          )}
                        </select>
                        {errors.Status && (
                          <div className="error-message" style={{color:'red'}}>
                            {errors.Status.message}
                          </div>
                        )}
                      </div>
                    )}
                    {(questions[currentQuestionIndex].id == 2 ||
                      questions[currentQuestionIndex].id == 8) &&
                      questions[currentQuestionIndex].options.map((option) => (
                        <div className="col-6 " key={option.id}>
                          <div onClick={toggleFocus()}></div>
                          <button
                            className="btn-comm qs-next3"
                            onClick={() => handleOptionClick(option.id)}
                          >
                            {option.text}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
                {questions[currentQuestionIndex].id == 1 && buttonRedirect.buttonRedirectionLink?
                  (<a
                    className="call-btn regNextBtn"
                    onClick={(e) => handleRedirection(e, "submit")}
                  >
                    Submit
                  </a>):
                  (questions[currentQuestionIndex].id == 1 && buttonRedirect.buttonRedirectionLink == '' ?
                  (<a
                    className="call-btn regNextBtn"
                    href={`tel: ${phoneNumber}`}
                    onClick={(e) => handleOptionClickBtn(e, "submit")}
                  >
                    Submit
                  </a>):''
                  )
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default FormUKPI_V7;
