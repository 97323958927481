import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import Thankyou from "./Components/Pages/Thankyou";
import { RedirectProvider } from "./Contexts/RedirectContext";
import NotFound from "./Components/Pages/NotFound";
import UKPI_V1 from "./Components/Pages/Splits/UKPI_V1";
import UKPI_V2 from "./Components/Pages/Splits/UKPI_V2";
import PrivacyPolicy from "./Components/Includes/Layouts/PrivacyPolicy";
import complaint from "./Components/Includes/Layouts/Complaint";
import UKPI_V3 from "./Components/Pages/Splits/UKPI_V3";
import UKPI_V4  from "./Components/Pages/Splits/UKPI_V4";
import UKPI_V5  from "./Components/Pages/Splits/UKPI_V5";
import UKPI_YH_ADV_V1 from "./Components/Pages/Advertorials/UKPI_YH_ADV_V1";
import UKPI_V6 from "./Components/Pages/Splits/UKPI_V6/UKPI_V6";
import UKPI_V7 from "./Components/Pages/Splits/UKPI_V7";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/thankyou"]} component={Thankyou} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={["/404"]} component={NotFound} />
              {/* <Route exact path={["/UKPI_V1"]} component={UKPI_V1} /> */}
              <Route exact path={["/UKPI_V1"]} component={NotFound} />
              <Route exact path={["/UKPI_V2"]} component={UKPI_V2} />
              <Route exact path={["/UKPI_V3"]} component={UKPI_V3} />
              <Route exact path={["/UKPI_V4"]} component={UKPI_V4} />
              <Route exact path={["/UKPI_V5"]} component={UKPI_V5} />
              <Route exact path={["/UKPI_V6"]} component={UKPI_V6} />
              <Route exact path={["/privacy"]} component={PrivacyPolicy} />
              <Route exact path={["/complaint"]} component={complaint} />
              <Route exact path={["/UKPI_V7"]} component={UKPI_V7} />
              <Route exact path={["/UKPI-YH-ADV-V1"]} component={UKPI_YH_ADV_V1} />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
