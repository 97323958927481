//     extra parameters                                         //
//     ----------------                                         //
//  -> first_question  (boolean)                                //
//  -> final_question  (boolean)                                //
//  -> specialcss                                               //
//     -- Eg: verticleButton                                    //
//  -> dummy_text                                               //
//     -- text which has to be displayed within options but     //
//        doesnt have to be shown in the user selected section  //
//////////////////////////////////////////////////////////////////

export const QandA_v6 = [
  {
    id: "2",
    question: [`Were you injured in the last 3 years?`],
    first_question: true,
    options: [
      { id: "8", text: "Yes" },
      { id: "9", text: "No" },
    ],
  },
  {
    id: "8",
    question: [`Are you already working with a solicitor?`],
    options: [
      { id: "25", text: "Yes" },
      { id: "26", text: "No" },
    ],
  },
  {
    id: "1",
    question: [
      `What type of accident was it?`,
    ],
    specialcss: "verticleButton",
    final_question: true,
    options: [
      { id: "1", text: "Accident At Work" },
      { id: "2", text: "Accident in a private place", dummy_text: " (shop, bar, restaurant etc.)" },
      { id: "3", text: "Accident in a public place", dummy_text: " (park, pavement etc.)" },
      { id: "4", text: "Road Traffic Accident" },
      { id: "5", text: "Industrial Illness" },
      { id: "6", text: "Criminal Injury" },
      { id: "7", text: "Other" },
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `Congratulations! 🤩🤩🥳🥳🥳`,
      `Tap the button below to call now and claim your benefits, it will only take you 2 to 3 minutes.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];