import { Helmet } from "react-helmet";
import { AppEnv } from "../../../../Constants/EnvConstants";

const Header = () => {
  return (
    <>
      {/* {(AppEnv == "prod") ?
        <Helmet>

        </Helmet>
        : */}
        <Helmet>
          <script src="//b-js.ringba.com/CAe2cad3c1157d424c9be5d56134af617b" async></script>
        </Helmet>
      {/* } */}
      <section>
        <div className="container text-center">
          <h5 className="mt-lg-4 mt-md-4 mt-sm-2 mt-2 text1">
            <div className="row mt-3">
              <div className="col-lg-10 offset-lg-1 ">
                <h1 className="  fw-bold text2">
                  Take This Free 30 Second Online Evaluation To Help Maximize Your
                  Potential Compensation
                </h1>
              </div>
            </div>
          </h5>
        </div>
      </section>
    </>
  )
}

export default Header