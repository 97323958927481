
const SimpleModal = ({modalBody,setModalBody}) => {
    const closeModal = () =>{
        setModalBody('');
    }
    return (
        <>
            <div
                className={`modal fade ${modalBody ===  '' ? '' : 'show'}`} 
                id="injuredModal"
                tabIndex={-1}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-bod modal_cs">
                           {modalBody}
                        </div>
                        <div className="modal-footer text-center">
                            <button
                                type="button"
                                className="btn btn-secondary mid_btn"
                                data-bs-dismiss="modal"
                                onClick={() => closeModal()}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default SimpleModal;