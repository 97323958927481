import React from "react";

const ContentSection = () => {
  return (
    <>
      <section className="content_section_part bg-white">
        <div className="container">
          <div className="offset-lg-1 col-lg-10 col-12">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                <h2 className="fw-bold mb-2">Fast Settlements</h2>
                <img src="assets/UKPI_V2/img/settlement.png" alt="" />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                <h2 className="fw-bold mb-2">Maximum Compensation</h2>
                <img src="assets/UKPI_V2/img/compansation.png" alt="" />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                <h2 className="fw-bold mb-2">No Win, No Fee*</h2>
                <img src="assets/UKPI_V2/img/nowinnofee.png" alt="" />
              </div>
              <div className="col-lg-12 col-12 text-center">
                {/* <h5 className="fw-bold mb-2">
                  We work with an expert panel of law firms. If we refer your
                  claim to one of our law firms, they will do their best to
                  secure the maximum compensation that you deserve and as
                  quickly as possible. As one of the top claims management
                  companies, you can rest easy knowing that your claim is in
                  safe hands with us and the solicitors that The Compensation
                  Experts work with.
                </h5> */}
                <h5 className="fw-bold mb-2">
                We work with an expert panel of law firms. If we refer your claim to one of our law firms, they will do their best to secure the maximum compensation that you deserve and as quickly as possible. As one of the top claims management companies, you can rest easy knowing that your claim is in safe hands with us and the solicitors that The Compensation Experts work with.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentSection;
