import react from 'react';
import "../../../assets/UKPI_V1/css/personel.scss";
import FormUKPI_V1 from "../../Forms/FormUKPI_V1";
import Footer from "../../Includes/Layouts/UKPI_V1/Footer";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Button from "../../UI/Button";

import FbViewPixel from '../../../Utility/FbViewPixel';
import { DynamicRoutingSplit } from '../../../Utility/DynamicRoutingSplit';

const UKPI_V1 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('UKPI_V1', 'UKPI_V1'); //for dynamic routing
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="UKPI_V1" />
      <FbViewPixel />
      <div className='UKPI_V1'>
        <div className="personal_injury" id="personal_injury">
          <section className="bannersec">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 text-center">
                  <img src="/assets/UKPI_V1/img/logo.png" alt="" />
                  <h1>Let our expert team <br /> help you decide</h1>
                  <h3>By answering a few simple questions we will be able to tell you if you may be owed thousands in compensation for a personal injury including: accidents at work, road traffic accidents, slips trips and falls + more.</h3>
                  <div className="trustpiolet text-center">
                    <a href="https://uk.trustpilot.com/review/the-compensation-experts.co.uk?utm_medium=trustbox&utm_source=MicroReviewCount" target="_blank">
                      <p>See our <strong>152</strong> reviews on</p>
                      <img src="/assets/UKPI_V1/img/logo-white.svg" alt="" />
                    </a>
                  </div>
                </div>
                <FormUKPI_V1 splitName={DynamicRouteSplitName} nextPage={DynamicRouteNextPage} />
              </div>
            </div>
          </section>
          {/* content-section */}
          <section className="content_section_one">
            <div className="container">
              <div className="offset-lg-1 col-lg-10 col-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                    <h2>Fast Settlements</h2>
                    <img src="/assets/UKPI_V1/img/settlement.png" alt="" />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                    <h2>Maximum Compensation</h2>
                    <img src="/assets/UKPI_V1/img/compansation.png" alt="" />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                    <h2>No Win, No Fee*</h2>
                    <img src="/assets/UKPI_V1/img/nowinnofee.png" alt="" />
                  </div>
                  <div className="col-lg-12 col-12 text-center">
                    <h2>We work with an expert panel of law firms. If we refer your claim to one of our law firms, they will do their best to secure the maximum compensation that you deserve and as quickly as possible. As one of the top claims management companies, you can rest easy knowing that your claim is in safe hands with us and the solicitors that The Compensation Experts work with.</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Agreement-section  */}
          <section className="agreement_section">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-10 col-12">
                  <h3>No Win, No Fee Agreement</h3>
                  <p><i>The solicitors we work with operate on a no-win-no-fee basis. Typically, customers pay 25% (inclusive of VAT) of the compensation amount that is recovered, although this is subject to your individual circumstances and the actual fee may be less than this. Should you terminate your agreement outside the 14-day cooling-off period, termination fees may apply.  </i></p>
                  <div className="mb-3 text-center">
                    {/* <button type="button" className="btn btn-primary">Start your claim</button> */}
                    <button
                      type="button"
                      className="btn claim_btn btn-primary"
                      // disabled={frmbtnDisabled}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '#';
                      }}

                      style={{ float: "none" }}
                    >Start your claim</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* FOOTER */}
          <Footer />
          {/* trustpiolet */}
          {/* <div class="trustpiolet text-center d-md-block d-none">
        <a href="https://uk.trustpilot.com/review/the-compensation-experts.co.uk?utm_medium=trustbox&utm_source=MicroReviewCount" target="_blank">
            <p>See our <strong>152</strong> reviews on</p>
            <img src="/assets/UKPI_V1/img/logo-white.svg" alt="">
        </a>
      </div> */}
          {/*body end */}
        </div>
      </div>

    </>
  );
};
export default UKPI_V1;























