import React,{ useEffect } from 'react';
import "../../../assets/UKPI_V1/css/privacy.scss";
import FooterPolicy from "./FooterPolicy";

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy';
        }, []);
    return (
    <>
      <div className="personal_injury" id="personal_injury">
      <header>
      <div className="container">
          <div className="row">
            <div className=" col-lg-12 col-12 text-start">
              <img className="compensation-logo" src="/assets/UKPI_V1/img/logo.png" alt="" />
            </div>
          </div>
        </div>
      </header>
     
      <section className="privacy">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <h2>National Injury Claimline Ltd.’s Privacy Policy</h2>
                    <p>This Privacy Policy explains how National Injury Claimline Ltd (Trading as The Compensation Experts, The Medical Negligence Experts) collects and processes personal data in relation to claimants and prospective claimants.</p>
                    <p>National Injury Claimline Ltd is the controller of personal data. National Injury Claimline Ltd is registered in England and Wales under the company registration number: 09944369. Any reference to ‘the controller’ within this Privacy Policy shall mean National Injury Claimline Ltd.</p>
                    <p>As a controller of personal data, National Injury Claimline Ltd is also registered with the Information Commissioner’s Office (ICO), registration details: ZA160649.</p>
                    <p>This Privacy Policy was last updated on 20/07/2022.</p>
                    <h4>Our contact details</h4>
                    <p className="mb-0">Name: National Injury Claimline Ltd </p>
                    <p className="mb-0">Address: 33 Ballbrook Avenue, Didsbury, Manchester, M20 3JG.</p>
                    <p className="mb-0">Phone Number: 0161 452 03 11</p>
                    <p>Email: <a href="mailto:data@national-injury-claimline.co.uk">data@national-injury-claimline.co.uk
                    </a></p>
                    <h4>Personal data we collect from you</h4>
                    <p>The controller may collect personal data directly from claimants and prospective claimants for various purposes for processing. </p>
                    <p>Examples of the categories of personal data that the controller may obtain directly from claimants/prospective claimants may include but are not necessarily limited to: identity information, contact information, location information, claims information, special category information (health data), employment information, and financial information.</p>
                    <p>The controller may obtain personal data directly from claimants and prospective claimants prior to and throughout the provision of claims management services, through the following means:</p>
                    
                    <ul className="mb-0 mbb">
                        <li className="ml-3">Direct interactions</li>
                        <li className="ml-5">Email correspondence</li>
                        <li className="ml-5">Telephone calls</li>
                        <li className="ml-5">Verbal interactions </li>
                    </ul>
                  
                    <h4>Personal data we collect from other sources</h4>
                    <p>From time to time, the controller may obtain personal data about employees from external sources. This includes:</p>


                    <table>
                        <tbody>
                            <tr>
                                <td><b>External Sources</b></td>
                                <td><b>Categories of Personal Data Obtained</b></td>
                            </tr>
                            <tr>
                                <td><i>Third-party claims management lead generation companies</i></td>
                                <td><i>Identity information, contact information, claim information, special category information (health data)</i></td>
                            </tr>
                            <tr>
                                <td><i>Any form of representative acting for and on behalf of individuals (irrespective of whether that individual is a prospective claimant)</i></td>
                                <td><i>This is subject to the nature of any such representations made by those acting for and on behalf of individuals (irrespective of whether a prospective claimant or not)</i></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>Lawful bases for processing personal data</h4>
                    <p>Under the UK General Data Protection Regulation (UK GDPR), the lawful bases that the controller relies upon to process personal data are described as follows:</p>
                    <ol className='mbb'>
                        <li>Consent</li>
                        <li>Legal obligation</li>
                        <li>Legitimate interest</li>
                    </ol>
                    <p>The controller may rely on further conditions for processing that are set out within Article 9 UK GDPR and Schedule 1 Part 1 of the Data Protection Act 2018.</p>
                    <h4>Purposes and lawful bases for processing personal data</h4>
                    <p>As the controller of claimants’ and prospective claimants’ personal data, there are a variety of purposes for which the controller processes personal data. The controller has set out the purposes for processing personal data about any claimants and prospective claimants and the lawful bases for doing so.</p>

                    <table>
                        <tbody>
                            <tr>
                                <td><b>Purposes for processing personal data</b></td>
                                <td><b>Lawful basis</b></td>
                            </tr>
                            <tr>
                                <td rowspan="2"><i>To contact potential claimants following the submission of an enquiry regarding the provision of claims management services (claims relating to personal injury, medical negligence, industrial disease)</i></td>
                                <td><i>Depending on the data that is captured within online submission forms, we may rely on Consent, Explicit Consent (health data)</i></td>
                            </tr>
                            <tr>
                                <td><i>Depending on the data that is captured within online submission forms, we may rely on legitimate interests (necessary for the controller to contact claimants/prospective claimants to provide requested services)</i></td>
                            </tr>
                            <tr>
                                <td rowspan="2"><i>To identify whether potential claimants may have a claim</i></td>
                                <td><i>Legitimate interests (necessary for the controller to provide requested services to claimants/prospective claimants</i></td>
                            </tr>
                            <tr>
                                <td><i>Explicit consent (health data)</i></td>
                            </tr>
                            <tr>
                                <td><i>To refer potential claimants to an appropriate law firm for claims investigations and representation where it has been identified that a potential claimant may have a claim (claims relating to personal injury, medical negligence, industrial disease, housing disrepair)</i></td>
                                <td><i>Consent, Explicit Consent (health data)</i></td>
                            </tr>
                            <tr>
                                <td><i>To contact prospective claimants with a view to referring them to a new law firm in the event that the original law firm to whom claimants are referred are unable to assist with a particular claim.</i></td>
                                <td><i>Legitimate interests (necessary for the controller to ensure that claimants are appropriately placed with a law firm for claims investigation and representation)</i></td>
                            </tr>
                            <tr>
                                <td><i>To request feedback from prospective claimants regarding the satisfaction of claims management services</i></td>
                                <td><i>Legitimate interests (necessary for us to improve the quality of services)</i></td>
                            </tr>
                            <tr>
                                <td><i>To assist and provide support to claimants in the event that they have been identified as vulnerable or have additional support needs relevant to the provision of claims management services</i></td>
                                <td><i>Consent, Explicit Consent (health data)</i></td>
                            </tr>
                            <tr>
                                <td><i>To share any vulnerability information with law firms to whom claimants are referred to ensure that any such law firm is appropriately aware of and supports vulnerable claimants, having consideration for any needs</i></td>
                                <td><i>Consent, Explicit Consent (health data)</i></td>
                            </tr>
                            <tr>
                                <td><i>To send direct marketing communications to prospective/current claimants</i></td>
                                <td><i>Consent</i></td>
                            </tr>
                            <tr>
                                <td><i>To manage/process direct marketing opt-out requests submitted by prospective/current claimants</i></td>
                                <td><i>Legal obligation</i></td>
                            </tr>
                            <tr>
                                <td><i>To screen against marketing suppression lists to ensure that marketing communications are not sent to prospective/current claimants that have opted out of/objected to receiving direct marketing communications</i></td>
                                <td><i>Legal obligation</i></td>
                            </tr>
                            <tr>
                                <td><i>To publish feedback provided by prospective/current claimants regarding the satisfaction of claims management services</i></td>
                                <td><i>Consent</i></td>
                            </tr>
                            <tr>
                                <td><i>To respond to and satisfy (where necessary) any data rights requests submitted by prospective/current claimants</i></td>
                                <td><i>Legal obligation</i></td>
                            </tr>
                            <tr>
                                <td><i>To respond to any complaints submitted by prospective/current claimants</i></td>
                                <td><i>Legitimate interests (necessary to ensure that the controller can comply with the FCA's rules and resolve complaints submitted by individuals)</i></td>
                            </tr>
                            <tr>
                                <td><i>Monitoring and oversight e.g., recording and monitoring telephone calls and communications for compliance with regulatory rules, and company processes and for quality and training purposes.</i></td>
                                <td><i>Legitimate interests (necessary to ensure that the controller can comply with the FCA's rules and resolve complaints submitted by individuals)</i></td>
                            </tr>
                            <tr>
                                <td><i>To communicate significant changes to the controller’s Privacy Policy.</i></td>
                                <td><i>Legal obligation</i></td>
                            </tr>
                            <tr>
                                <td><i>Record keeping</i></td>
                                <td><i>Legitimate interests (necessary to ensure that the controller can comply with the FCA’s rules and various regulatory requirements)</i></td>
                            </tr>
                            <tr>
                                <td><i>Financial crime matters</i></td>
                                <td><i>Legal obligation</i></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 className="mt-3">Disclosure of your personal data</h4>
                    <p>Throughout the provision of claims management services, the controller may disclose claimants’ and prospective claimants’ personal data to other controllers and processors for the purposes described within this Privacy Policy. </p>

                    <table>
                        <tbody>
                            <tr>
                                <td><b>Third-Party</b></td>
                                <td><b>Category of Recipient</b></td>
                            </tr>
                            <tr>
                                <td><i>Email, messaging and call service providers</i></td>
                                <td><i>Processor</i></td>
                            </tr>
                            <tr>
                                <td><i>Customer relationship management software</i></td>
                                <td><i>Processor</i></td>
                            </tr>
                            <tr>
                                <td><i>Cloud storage providers</i></td>
                                <td><i>Processor</i></td>
                            </tr>
                            <tr>
                                <td><i>Financial Ombudsman Service</i></td>
                                <td><i>Controller</i></td>
                            </tr>
                            <tr>
                                <td><i>Action Fraud</i></td>
                                <td><i>Controller</i></td>
                            </tr>
                            <tr>
                                <td><i>National Crime Agency (NCA)</i></td>
                                <td><i>Controller</i></td>
                            </tr>
                            <tr>
                                <td><i>Regulators</i></td>
                                <td><i>Controllers</i></td>
                            </tr>
                            <tr>
                                <td><i>Solicitors/Law firms</i></td>
                                <td><i>Controllers</i></td>
                            </tr>
                            <tr>
                                <td><i>Law enforcement authorities</i></td>
                                <td><i>Controller</i></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 className="mt-3">Additional information regarding the disclosure of your personal data</h4>
                    <ol className='mbb'>
                        <li>Claimants’ and prospective claimants’ personal data may be shared between any subsidiary, holding company, or any other member of the controller’s group.</li>
                        <li>If the controller is under a duty to disclose or share personal data to comply with any legal obligation, or to enforce or apply the controller’s terms of use and other agreements; or to protect the rights, property, or safety of the controller, the controller’s clients, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection.</li>
                        <li>If the controller sells or buys any business or assets, in which case the controller may disclose personal data to the prospective seller or buyer of any such business or assets.</li>
                        <li>Personal data may be disclosed where it is necessary to protect the controller’s rights, property, or personal safety, or to respond to requests by public, regulatory, or law enforcement authorities, including to meet national security or law enforcement requirements.</li>
                    </ol>
                    <h4>Data retention</h4>
                    <p>To determine the appropriate retention period for personal data, the controller considers the amount, nature and sensitivity of the personal data, the potential risk, of harm from unauthorised use or disclosure of personal data, and the purposes for which the controller processes personal data and whether the controller can achieve those purposes through other means, including applicable legal requirements.</p>
                    <p>Details of retention periods for different aspects of personal data are available upon request.</p>
                    <h4>International transfers of personal data</h4>
                    <p>Personal data is not transferred outside of the UK or EEA. Should this change in the future, this Privacy Policy will be updated to reflect the security measures that are in place to ensure that any such transfer is subject to an adequacy decision or appropriate safeguard, where applicable.</p>
                    <h4>Your data protection rights</h4>
                    <p>Under data protection law, any individuals acting for and on behalf of potential or existing clients have rights including:</p>
                    <p><b>Your right of access</b> - You have the right to ask the controller for copies of your personal information. </p>
                    <p><b>Your right to rectification</b> - You have the right to ask the controller to rectify personal information you think is inaccurate. You also have the right to ask the controller to complete information you think is incomplete.</p>
                    <p><b>Your right to erasure</b> - You have the right to ask the controller to erase your personal information in certain circumstances.</p>
                    <p><b>Your right to restriction of processing</b> - You have the right to ask the controller to restrict the processing of your personal information in certain circumstances.</p>
                    <p><b>Your right to object to processing</b> - You have the right to object to the processing of your personal information in certain circumstances.</p>
                    <p><b>Your right to data portability</b> - You have the right to ask that the controller transfers the personal information you gave the controller to another organisation, or to you, in certain circumstances.</p>
                    <p>You are not required to pay any charge for exercising your rights. If you make a request, the controller has one month to respond to you.</p>
                    <p><b>Your right to withdraw consent</b> – If you have provided the controller with consent for the processing of your personal data as may be the case for direct marketing, you have the right to withdraw that consent.</p>
                    <p>If you wish to make a request and/or would like to withdraw consent to the processing of any personal data, please contact the controller by using the following contact information:</p>
                    <p className="mb-0"><b>Address:</b> 33 Ballbrook Avenue, Didsbury, Manchester, M20 3JG.</p>
                    <p className="mb-0"><b>Phone Number:</b> 0161 452 03 11</p>
                    <p><b>Email:</b> <a href="mailto:data@national-injury-claimline.co.uk"> data@national-injury-claimline.co.uk</a></p>
                    <h4>How to complain</h4>
                    <p>If you have any concerns about the controller’s use of your personal information, you can make a complaint to the controller by using the contact information provided within this Privacy Policy. </p>
                    <p>You can also complain to the ICO if you are unhappy with how the controller has used your personal data. The controller would, however, appreciate the opportunity to resolve any complaints directly with you.</p>
                    <p>The ICO’s address:</p>
                    <p className="mb-0">Information Commissioner’s Office</p>
                    <p className="mb-0">Wycliffe House</p>
                    <p className="mb-0">Water Lane</p>
                    <p className="mb-0">Wilmslow</p>
                    <p className="mb-0">Cheshire</p>
                    <p>SK9 5AF</p>
                    <p>Helpline number: 0303 123 1113</p>
                    <p>ICO website: <a href="https://ico.org.uk/">https://www.ico.org.uk</a></p>
                    <h4>Changes to this Privacy Policy</h4>
                    <p>The controller is entitled to change this Privacy Policy at any time whether or not required by law. The controller will inform claimants/prospective claimants about changes to this Privacy Policy where the controller makes a substantial change. This Privacy Policy was last updated on 20/07/2022.</p>  
                </div>
            </div>
        </div>
    </section>

    <FooterPolicy/>
   
   

    </div>
        </>
    );
};

export default PrivacyPolicy;