import React, { useState, useEffect } from "react";
import { PhoneNumberConstants } from "../Constants/PhoneNumberConstants";
import { AppEnv } from "../Constants/EnvConstants";

const RingbaPhoneNumberEnglish = (utmName) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState();

  useEffect(() => {
    // if (AppEnv == "prod") {
      if (PhoneNumberConstants[utmName] == undefined) {
        setPhoneNumber(PhoneNumberConstants['default']);
      } else {
        setPhoneNumber(PhoneNumberConstants[utmName]);
      }
    // } else {
    //   setPhoneNumber(PhoneNumberConstants['default_local_NO']);
    // }
  }, []);
  console.log("phoneNumber: " + phoneNumber);
  console.log("utmName", utmName);

  useEffect(() => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, '').slice(-10);
      if (digits.length === 10) {
        const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        setFormattedPhoneNumber(formattedNumber);
      }
    }
  }, [phoneNumber]);

  return { phoneNumber, formattedPhoneNumber }
}

export default RingbaPhoneNumberEnglish;