import axios from "axios";
import { AppEmailValidateSingleApi, AppAdtopiaToken, AppEmailWebhookApi, AppGmailWebhookApi } from "../Constants/EnvConstants";
require("dotenv").config();
const base_api_url = process.env.REACT_APP_API_URL;
const api_auth_token = process.env.REACT_APP_API_AUTH_TOKEN;
export const Api = axios.create({
  baseURL: base_api_url,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + api_auth_token,
  },

});

export const CommonEmailValidateApiTimeDelay = axios.create({
  baseURL: 'https://dev.app.leadfinery.com/api/emvs037/dev/v1',
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken
  }
});

export const CommonEmailValidateApi = axios.create({
  baseURL: AppEmailValidateSingleApi,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken
  }
});


export const WebHookApi = axios.create({
  baseURL: AppEmailWebhookApi,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  }
});

export const GmailWebhookApi = axios.create({
  baseURL: AppGmailWebhookApi,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});
