export const split_Flow = 
    {
        default:{
            1:'UKPI_V1', 
            2:'thankyou',
        },
        UKPI_V1:{
            1:'UKPI_V1', 
            2:'thankyou',
        },
        UKPI_V2:{
            1:'UKPI_V2', 
            2:'thankyou',
        },
        UKPI_V3:{
            1:'UKPI_V3', 
            2:'thankyou',
        },
        UKPI_V4:{
            1:'UKPI_V4', 
            2:'thankyou',
        },
        UKPI_V5:{
            1:'UKPI_V5', 
            2:'thankyou',
        },
    }