import React, { useState } from "react";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import AnchorAdv from "../../UI/AnchorAdv";
import AdvFooter from "../../Includes/Layouts/UKPI_YH_ADV_V1/AdvFooter";
import "../../../assets/UKPI_YH_ADV_V1/css/custom.scss";
import "../../../assets/UKPI_YH_ADV_V1/css/animate.min.css";


const UKPI_YH_ADV_V1 = () => {
    return(
         <div className="page_centercont">
            <AdtopiaLoadAP pageType="AP" splitName="UKPI-YH-ADV-V1" />
            <section className="home-main text-center pb-5">
                <p className="text-center mt-2 mb-2 advert">Advertorial</p>
                <div>
                    <div className="mb-4 mt-5 animate__animated animate__fadeIn animate__delay-1s">
                        <img width={100} src="/assets/UKPI_YH_ADV_V1/img/logo.png" alt="" />
                    </div>
                    <div className="animate__animated animate__fadeInUp   animate__delay-1s">
                        <h1>Suffered an injury at <br /> work in the last two years? </h1>
                            <h4 className="mt-3">Find out if you're eligible to claim maximum compensation!</h4>
                                <h6 className="mt-3">Click below to start our FREE, no-obligation form and find out how much you could be owed.</h6>
                                <div className="text-center mt-3">
                                    {/* <button className="btn btn_slide mt-3">Start Your Free Case Evaluation Now</button> */}
                                    <AnchorAdv dataId="MAIN_CTA_BTN" className="btn btn_slide mt-3">Start Your Free Case Evaluation Now</AnchorAdv>
                               </div>
                    </div>
             </div>
         </section> 
        <AdvFooter/>
    </div> 
    )
}
export default UKPI_YH_ADV_V1;