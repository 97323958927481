import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";
const BasicDetails = ({
  className,
  validation,
  validationMsg,
  trigger,
  slideChange,
  prevSlide,
  textHeading1,
  textHeading2,
  textHeading3,
  getValues,
  setError
}) => {
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let txtLNameResult;
    let txtFNameResult;
    const values = getValues();
    const formDataT = values;
    const txtFirstName = formDataT.txtFName.trim();
    const txtLastName = formDataT.txtLName.trim();
    console.log(txtFirstName.length);

    txtFNameResult = await trigger("txtFName");
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
      document.getElementById("txtLName").focus();

    } else {
      document.getElementById("txtFName").focus();
      return false;
    }

    if (!txtFNameResult || !txtLNameResult) {
      errorFlag = 1;
    }
   
    if(txtFirstName.length < 3) {
      setError('txtFName', {
        type: "manual",
        message: "Your First Name is Invalid. Please Recheck",
      });
      document.getElementById("txtFName").focus();
      errorFlag = 1;
      return false;
    }
 
    if(txtLastName.length < 3) {
      setError('txtLName', {
        type: "manual",
        message: "Your Last Name is Invalid. Please Recheck",
      });
      document.getElementById("txtLName").focus();
      errorFlag = 1;
      return false;
    }


    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  return (
    <>
      <div id="slide8" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center  p-3 firstslide">
          <div className="my-3">
            {/* <p className="mb-0 fs-5 fw-bold para">{textHeading1}</p> */}
            <label className="mb-1  fw-bold qstn_1">{textHeading2}</label>
            <h5>{textHeading3}</h5>
            <div className="form-group mb-3">
              <TextField
                type="text"
                className="form-control p-3"
                placeholder="First Name"
                onBlur={namevalidate}
                name="txtFName"
                id="txtFName"
                validation={validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.txtFName && validationMsg.txtFName.message
                }
              ></TextField>
            </div>
            <div className="form-group">
              <TextField
                type="text"
                className="form-control  p-3"
                placeholder="Surname"
                onBlur={namevalidate}
                name="txtLName"
                id="txtLName"
                validation={validation({
                  required: "Please Enter Surname",
                  minLength: {
                    value: 2,
                    message: "Please Enter Valid Surname",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Surname is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.txtLName && validationMsg.txtLName.message
                }
              ></TextField>
            </div>
          </div>
          <InputButton
            name="basic_details"
            className="next-but btn my-bt flasher2 sctop1 text-white fw-bold rounded mb-3"
            value="Continue >>"
            btnType="button"
            onClick={nextSlide}
          />
          <a name="question_3" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/UKPI_V2/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text">
                <img
                  src="assets/UKPI_V4/img/ico-safe.png"
                  className="safe-text"
                  alt=""
                />{" "}
                Safe | Secure | Privacy Protected
              </div>
              <div className="form-check p-2 offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
                <label
                  className="form-check-label text-consent text-black fs-6 mt-0"
                  htmlFor="accept"
                >
                  I consent for National Injury Claimline Ltd (The Compensation
                  Experts) to process the above data and (personal injury)
                  health data to contact me in relation to a possible claim. To
                  read more about how we process your personal data, please see
                  our{" "}
                  <a href="/privacy" target="_blank">
                    {" "}
                    Privacy Policy.
                  </a>
                </label>
              </div>
      </div>        
    </>
  );
};
export default BasicDetails;
