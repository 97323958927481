import React, { useEffect, useState } from "react";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import InputButton from "../../../UI/InputButton";
import GtmDataLayer from "../../GtmDataLayer";
import star from "../../../../assets/UKPI_V2/img/stars-4.5.svg";
import info from "../../../../assets/UKPI_V2/img/info.jpg";
import TrustBox from "../../../UI/TrustBox";

const BasicQuestionnaireFiveNew = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
  partsSelected,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [partsLabels] = useState([]);
  const [selectedLabels,setSelectedLabels] = useState([]);
  
  const checkedPart = async (e) => {
    let selectedValue = e.target.value;
    let checkedStatus = e.target.checked;
    let selectedLabel = e.target.getAttribute('data-value');

    if (checkedStatus == true) {
      partsSelected.push({ id: selectedValue });
      partsLabels.push({label :selectedLabel });
      setErrorMsg("");
    } else {
      const decre = partsSelected.map((selectedArray, index) => {
        if (selectedValue == selectedArray.id) {
          partsSelected.splice(index, 1);
          partsLabels.splice(index, 1);
        }
      });
    }
    let selectedLabels = partsLabels.map((a) => a.label);
    let commaSeperated = selectedLabels.toString();
    setSelectedLabels(commaSeperated);
    console.log(partsSelected)
  };

  const nextSlide = (e) => {
    let selectedCount = partsSelected.length;
    if (selectedCount == 0) {
      setErrorMsg("Please select your option(s)");
    } else {
      setErrorMsg("");

      GtmDataLayer({
        question:'Injured body part?',
        answer:selectedLabels,
      });

      slideChange(e);
    }
  };

  return (
    <>
      <div id="slide5" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center p-3 pb-0 firstslide">
          <div className="my-0 my-md-3">
            <label className="mb-0  fw-bold qstn_1">{textHeading}</label>

            <CheckBoxV2
              id="18"
              value="18"
              name="question_7[]"
              className="custom-checkbox opacity-0"
              labelName="Head"
              labelClassName="icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad "
              onClick={checkedPart}
              validation={validation()}
            />

            <CheckBoxV2
              id="19"
              value="19"
              name="question_7[]"
              className="custom-checkbox opacity-0"
              labelName="Neck"
              labelClassName="icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad "
              onClick={checkedPart}
              validation={validation()}
            />

            <CheckBoxV2
              id="20"
              value="20"
              name="question_7[]"
              className="custom-checkbox opacity-0"
              labelName="Back"
              labelClassName="icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad "
              onClick={checkedPart}
              validation={validation()}
            />

            <CheckBoxV2
              id="21"
              value="21"
              name="question_7[]"
              className="custom-checkbox opacity-0"
              labelName="Leg"
              labelClassName="icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad"
              onClick={checkedPart}
              validation={validation()}
            />

            <CheckBoxV2
              id="22"
              value="22"
              name="question_7[]"
              className="custom-checkbox opacity-0"
              labelName="Other"
              labelClassName="icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad"
              onClick={checkedPart}
              validation={validation()}
            />
          </div>
          <div className="col-lg-12 text-center">
            <span className="error_msg">{errorMsg}</span>
          </div>
          {/* <InputButton
            name="question_7"
            className="next-but btn my-bts sctop1 text-white fw-bold rounded"
            value="Next >>"
            btnType="button"
            onClick={nextSlide}
          /> */}
          <InputButton
            name="question_7_v5"
            className={`next-but btn special_button sctop1 text-white fw-bold rounded mb-2`}
            value="Next >"
            btnType="button"
            onClick={nextSlide}
          />
          <a name="question_4" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/UKPI_V2/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className=" col-lg-12 col-12 trust_pi mb-2">
                    <a
                      href="https://www.trustpilot.com/review/the-compensation-experts.co.uk"
                      target="_blank"
                    >
                      <div
                        className="trust-sec-widget"
                      >
                        <TrustBox />
                      </div>
        

                      <div className="row box-a mds-4 "> 
                         <div className="col-7 p-0 trustpilot"> 
                         <img src={star} className="img-star" alt="" />
                          </div>
                         <div className="col-3 p-0 d-flex flex-row align-items-center"> 
                         <div> 
                         <p> 
                          <b className="rate-num px-2"> 4.6</b>
                          </p>
                         </div>
                          <div className="last-trust-img"> 
                          <img
                          src={info}
                          className="info_img"
                          alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                                            Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                        />
                          </div>
                         </div>
                      </div>
                    </a>
                  </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireFiveNew;
