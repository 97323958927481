import React, {
    useEffect,
    useState,
    useRef,
    useReducer,
    useContext,
  } from "react";
  import { useForm } from "react-hook-form";
  import VisitorParamsContext from "../../Contexts/VisitorParams";
  import { useDataIngestion } from "../../Hooks/useDataIngestion";
  import QueryStringContext from "../../Contexts/QueryString";
  import * as EnvConstants from "../../Constants/EnvConstants";
  import { useHistory } from "react-router-dom";
  import ProgressBar from "../Includes/Layouts/UKPI_V5/ProgressBar";
  import BasicQuestionnaireOne from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireOne";
  import BasicQuestionnaireTwo from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireTwo";
  import BasicQuestionnaireThree from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireThree";
  import BasicQuestionnaireFour from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireFour";
  import BasicQuestionnaireFive from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireFive";
  import BasicQuestionnaireSix from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireSix";
  import BasicQuestionnaireSeven from "../Includes/Layouts/UKPI_V5/BasicQuestionnaireSeven";
  import BasicDetails from "../Includes/Layouts/UKPI_V5/BasicDetails";
  import ContactDetails from "../Includes/Layouts/UKPI_V5/ContactDetails";
  import GtmDataLayer from "../Includes/GtmDataLayer";
import { getConversionValue } from "../../Constants/ConversionValueV5";
  
  const FormUKPI_V5 = (props) => {
    const [progress, setProgress] = useState(0);
    const { saveDataIngestion, isLoading } = useDataIngestion();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { queryString } = useContext(QueryStringContext);
    const [partsSelected] = useState([]);
    const field = "pid";
    const history = useHistory();
  
    const initialState = {
      showSlide1: "show",
      showSlide2: "hide",
      showSlide3: "hide",
      showSlide4: "hide",
      showSlide5: "hide",
      showSlide6: "hide",
      showSlide7: "hide",
      showSlide8: "hide",
      showSlide9: "hide",
      showSlide10: "hide",
    };
    const FormReducer = (state, action) => {
      let answr = action.payload.clickedSlide.dataValue;
      switch (action.type) {
        case "showSlide": {
          if (action.payload.clickedSlide.slide === "question_0") {
            setProgress(0);
            return {
              ...state,
              showSlide1: "show",
              showSlide2: "hide",
            };
          }
          if (action.payload.clickedSlide.slide === "question_2") {
            setProgress(20);
            GtmDataLayer({
              question:'Injured in 3 years?',
              answer:answr,
            });
            return {
              ...state,
              showSlide1: "hide",
              showSlide2: "show",
              showSlide3: "hide",
            };
          } else if (action.payload.clickedSlide.slide === "question_3") {
            setProgress(30);
            GtmDataLayer({
              question:'working with an attorney?',
              answer:answr,
            });
            return {
              ...state,
              showSlide2: "hide",
              showSlide3: "show",
              showSlide4: "hide",
            };
          } else if (action.payload.clickedSlide.slide === "question_4") {
            setProgress(40);
            GtmDataLayer({
              question:'Was the accident your fault?',
              answer:answr,
            });
            return {
              ...state,
              showSlide3: "hide",
              showSlide4: "show",
              showSlide5: "hide",
            };
          }  else if (action.payload.clickedSlide.slide === "question_5") {
            setProgress(50);
            GtmDataLayer({
              question:'Were you physically hurt?',
              answer:answr,
            });
            return {
              ...state,
              showSlide4: "hide",
              showSlide5: "show",
              showSlide6: "hide",
            };
          }
          else if (action.payload.clickedSlide.slide === "question_7_v5") {
            setProgress(60);
            GtmDataLayer({
              question:'What part(s) of your body did you injure?',
              answer:answr,
            });
            return {
              ...state,
              showSlide5: "hide",
              showSlide6: "show",
              showSlide7: "hide",
            };
          }else if (action.payload.clickedSlide.slide === "question_6") {
            setProgress(70);
            GtmDataLayer({
              question:'Did the accident cause hospitalization or medical treatment?',
              answer:answr,
            });
            return {
              ...state,
              showSlide6: "hide",
              showSlide7: "show",
              showSlide8: "hide",
            };
          }else if (action.payload.clickedSlide.slide === "question_1") {
            setProgress(80);
            const conversionValue = getConversionValue(answr);

            GtmDataLayer({
              question: "How did you get injured?",
              answer: answr,
              conversionValue: conversionValue?.value,
              optionValue: conversionValue?.optionValue,
              injuryType: answr,
            });
            localStorage.setItem("conversionValue", conversionValue?.value);
            localStorage.setItem("injuryType", answr);
            
            return {
              ...state,
              showSlide7: "hide",
              showSlide8: "show",
              showSlide9: "hide",
            };
          }
            else if (action.payload.clickedSlide.slide === "basic_details") {
            setProgress(99);
            GtmDataLayer({
              question:'Personal Details',
              answer:'Completed',
            });
            return {
              ...state,
              showSlide8: "hide",
              showSlide9: "show",
              showSlide10: "hide",
            };
          }  else {
            return {
              ...state,
            };
          }
        }
        default: {
        }
      }
    };
    const splitForm = useRef(null);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const { register, errors, trigger, setError, clearErrors, getValues } =
      useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
      });
    const slideChange = (e) => {
      document.getElementById("div_form").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
      setClickedSlide({ slide: e.target.name, dataValue:e.target.getAttribute('data-value') });
    };
  
    const prevSlide = (e) => {
      document.getElementById("div_form").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
      setClickedSlide({ slide: e.target.name, dataValue: null });
    };
  
    useEffect(() => {
      if (clickedSlide) {
        dispatch({ type: "showSlide", payload: { clickedSlide } });
      }
    }, [clickedSlide]);
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const formSubmit = async (redirection) => {
      GtmDataLayer({
        question:'Phone, Email',
        answer:'Completed',
      });
      const values = getValues();
      const formData = values;
      console.log(values);
      formData.txtFName = values.txtFName;
      formData.txtLName = values.txtLName;
      formData.txtEmail = values.txtEmail;
      formData.txtPhone = values.txtPhone;
      let bodyParts = partsSelected.map((a) => a.id);
      formData.question_7 = bodyParts;
      formData.page_name = props.splitName;

  
      localStorage.setItem("formData", JSON.stringify(formData));
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "UKPI_V5",
        queryString,
        visitorParameters.data
      );
  
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            if (EnvConstants.AppEnv === 'dev' || EnvConstants.AppEnv === "pre" || EnvConstants.AppEnv === "prod") {
              history.push("/fbpixel?split_name=" + props.splitName +"&next_page=" + props.nextPage ); // fb fixel firing
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
              );
            }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
          );
        }
      }
    };
  
    return (
      <>
            {/* <!--  ========= FormSection  Area Start  ========= --> */}
          <div id="div_form">
          <form
            ref={splitForm}
            name="split_form"
            id="user_form"
            method="POST"
            autoComplete="off"
          >
            <ProgressBar value={progress} />
            <BasicQuestionnaireTwo
              className={state.showSlide1}
              textHeading="Were you injured in the last 3 years?"
              validation={register}
              slideChange={slideChange}
              prevSlide={prevSlide}
              getValues={getValues}
            />
  
            <BasicQuestionnaireThree
              className={state.showSlide2}
              textHeading="Are you already working with an attorney?"
              validation={register}
              slideChange={slideChange}
              prevSlide={prevSlide}
              getValues={getValues}
            />
            <BasicQuestionnaireOne
              className={state.showSlide3}
              textHeading="Was the accident your fault?"
              validation={register}
              slideChange={slideChange}
              getValues={getValues}
              prevSlide={prevSlide}
            />
            <BasicQuestionnaireFour
            className={state.showSlide4}
            textHeading="Were you physically hurt?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            prevSlide={prevSlide}
          />
          <BasicQuestionnaireFive
            className={state.showSlide5}
            textHeading="What part(s) of your body did you injure?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            prevSlide={prevSlide}
            partsSelected={partsSelected}
          />
          <BasicQuestionnaireSix
            className={state.showSlide6}
            textHeading="Did the accident cause hospitalization or medical treatment?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            prevSlide={prevSlide}
          />
          <BasicQuestionnaireSeven
            className={state.showSlide7}
            textHeading="How did you get injured?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            prevSlide={prevSlide}
          />
            {/* <BasicDetails
              className={state.showSlide8}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              slideChange={slideChange}
              prevSlide={prevSlide}
              getValues={getValues}
              setError={setError}
              textHeading1="Great News! It appears you may have a successful compensation claim."
              textHeading2="Please Confirm Your Legal Name"
              textHeading3="This should appear as it would on your compensation cheque"
            ></BasicDetails> */}
  
            <ContactDetails
              className={state.showSlide8}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              slideChange={slideChange}
              prevSlide={prevSlide}
              getValues={getValues}
              setError={setError}
              textHeading="Congratulations. We have exciting news to share. Enter your details below to reveal the details of your case."
              formSubmit={formSubmit}
            ></ContactDetails>
  
            {/* <div className="text-center safe-text">
              <img
                src="assets/CCG_PI_V1/img/ico-safe.png"
                className="safe-text"
                alt=""
              />{" "}
              Safe | Secure | Privacy Protected
            </div>
            <div className="form-check offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
              <label
                className="form-check-label text-black fs-6 mt-0"
                htmlFor="accept"
              >
                I consent for compensation genie to process the above data and
                (personal injury) health data to contact me in relation to a
                possible claim. To read more about how we process your personal
                data, please see our{" "}
                <a
                  href="https://form.compensationgenie.com/privacy.html"
                  target="_blank"
                >
                  {" "}
                  Privacy Policy.
                </a>
              </label>
            </div> */}
            </form>
            </div>
      </>
    );
  };
  
  export default FormUKPI_V5;
  