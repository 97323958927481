import React, { useEffect, useState, useRef } from "react";
import { QandA_v6 } from "../../../../Constants/ChatbotConstants/ChatbotConstant";
// import SimpleModal from "./SimpleModal";

import loader from "../../../../assets/UKPI_V6/img/loader.gif";
import avatar2 from "../../../../assets/UKPI_V6/img/avatar2.png";
import avatar from "../../../../assets/UKPI_V6/img/avatar.png";

import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../../Hooks/useJourneyStats";
import UnqualifiedRedirectionLinkEnglish from "../../../../Utility/UnqualifiedRedirectionLinkEnglish";

const ChatBotBubble_UKPI_V6 = ({
  formSubmit,
  journeyStatus,
  saveUser,
  uuid,
  splitName,

  is_online,
  buttonRedirect,
  phoneNumber,
  formattedPhoneNumber
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [renderOption, setRenderOption] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [displayDelay, setDisplayDelay] = useState(2000); // Delay for questions
  const [modalBody, setModalBody] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const autofocus = useRef();

  useEffect(() => {
    if (uuid) {
      (async () => {
        await saveJournyStats(
          uuid,
          'question',
          questions[0].first_question === true ? questions[0].id : "",
          '',
          'view',
          splitName
        );
      })();
    }

    const query = new URLSearchParams(window.location.search);
    const devcommand = query.get("devcommand");
    if (devcommand === "fast") {
      setDisplayDelay(0);
    }
  }, [uuid]);

  const questions = QandA_v6;

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      splitName,
    );
    window.location = UnqualifiedRedirectionLinkEnglish(questions[currentQuestionIndex].options.find((opt) => opt.text === selectedOption).id).redirectionLink;
  }

  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      { "uuid": uuid },
      [click_status, click_value],
      "offline",
      splitName
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        splitName
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };

  const handleOptionSelect = (option) => {
    // Create a copy of the chat history with the user's response and the selected option.
    const updatedChatHistory = [...chatHistory];
    // Pass question id and option id to a function for sending to the backend.
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = selectedQuestion.options.find((opt) => opt.text === option).id;

    // conditons can be set here based on questions or options
    const conditions = [
      // ---- Example ----
      // { questionId: 'sampleID', optionIds: ['sampleIDs','sampleIDs','sampleIDs'] },
    ];

    function checkConditions(questionId, optionId) {
      for (const condition of conditions) {
        if (questionId === condition.questionId && condition.optionIds.includes(optionId)) {
          return true;
        }
      }
      return false;
    }

    if (questionId === "CALL_CLICK") {
      journeyStatus({
        slide_type: 'button',
        slide_question: 'CALL_CLICK',
        slide_value: '',
        type: 'click',
      });
      saveUser('CALL_CLICK', 1);
    } else if (checkConditions(questionId, optionId)) {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      journeyStatus({
        slide_type: 'question',
        slide_question: questionId,
        slide_value: optionId,
        type: 'click',
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      setModalBody("¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳");

      return false;
    } else {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      const storedOptions = localStorage.getItem('selectedOptions');
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
      parsedOptions['question_' + questionId] = optionId;
      localStorage.setItem('selectedOptions', JSON.stringify(parsedOptions));

      toggleFocus(questionId);

      journeyStatus({
        slide_type: questionId === 'CALL_CLICK' ? 'button' : 'question',
        slide_question: questionId,
        slide_value: optionId,
        type: 'click',
      });
      // Here, you can call a function to send questionId and optionId to the backend.
      if (questions[currentQuestionIndex].final_question === true) {
        formSubmit(JSON.parse(localStorage.getItem('selectedOptions')));
      }
    }

    // Move to the next question or end the conversation when all questions are answered.
    if (currentQuestionIndex + 1 < questions.length) {
      // if (questionId === '8' && optionId === '17') {
      //   setCurrentQuestionIndex(currentQuestionIndex + 2);
      // } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      // }
      setRenderOption(false);
      setSelectedOption(""); // Clear the selected option for the next question.
    } else {
      // Conversation ended. You can handle this as needed.
      // For example, display a closing message.
    }
  };

  useEffect(() => {
    localStorage.removeItem("selectedOptions");
  }, []);

  useEffect(() => {
    const questionDelay = displayDelay; // Always apply the delay.
    const segment = questions[currentQuestionIndex];
    let currentIndex = 0; // Index for the current question within the segment.

    let qno = 0;

    // Function to display the next question within the segment.
    const displayNextQuestion = () => {
      if (currentIndex < segment.question.length) {
        const currentQuestion = segment.question[currentIndex];
        if (currentQuestion) {
          setChatHistory((prevChat) => [
            ...prevChat,
            { role: "bot", text: "Loading" },
          ]);
          setTimeout(() => {
            setChatHistory((prevChat) => {
              const updatedChat = [...prevChat];
              updatedChat.pop(); // Remove the last message, which is "Loading..."

              // Check if it's the last question in the group
              qno++;
              const isLastQuestion =
                qno === segment.question.length ? segment.id : false;

              const newMessage = {
                role: "bot",
                text: currentQuestion,
                last_question: isLastQuestion,
              };

              if (isLastQuestion !== false && uuid !== '' && segment.first_question !== true) {

                journeyStatus({
                  slide_type:
                    questions[currentQuestionIndex].id === 'CALL_CLICK' ? 'button' : 'question',
                  slide_question: segment.id,
                  slide_value: '',
                  type: 'view',
                });
              }

              return [...updatedChat, newMessage];
            });

            if (currentIndex === segment.question.length) {
              // Display options after all questions in the segment.
              if (segment.options && segment.options.length > 0) {
                setRenderOption(true);
              }
            }
          }, displayDelay);
        }

        currentIndex++; // Move to the next question.
        setTimeout(
          displayNextQuestion,
          currentIndex === segment.question.length ? 0 : questionDelay
        ); // Apply the delay only after the last question.
      }
    };

    // Automatically initiate the conversation with the first question.
    const timer = setTimeout(displayNextQuestion, questionDelay);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex, displayDelay, questions]);

  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = questions[currentQuestionIndex].specialcss === 'verticleButton' ? -120 : -445;
      console.log(questions[currentQuestionIndex].specialcss);

      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });

    } else {
      window.scroll(0, 0);
    }
  }

  return (
    <div>
      {chatHistory.map((message, index) =>
        message.role === "bot" ? (
          <div key={index} className="chater-prof">
            <div onClick={toggleFocus()}></div>
            <div>
              {message.text === "Loading" || message.last_question === true ? (
                <div className="supporter-dp">
                  <img src={avatar} alt="" />
                </div>
              ) : (
                <div
                  className="supporter-dp"
                  style={{ backgroundColor: "transparent", border: "none" }}
                ></div>
              )}
            </div>
            <div>
              {message.text === "Loading" ? (
                <div className="msg-loader loader1">
                  <div className="text-msg">
                    <img src={loader} alt="" />
                  </div>
                </div>
              ) : (
                <div className="mesg1">
                  <span
                    className={`text-msg text-message-item slide-top ${message.unique ?
                      message.unique : ""}`}
                  >
                    {message.text}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div key={index} className="client-replay my-3">
            <div onClick={toggleFocus()}></div>
            <div className="client-replay-vpx ">
              <div className="dp-client">
                <img src={avatar2} alt="" />
              </div>
              <div className="sel-option">
                <span className="action-btn text-message-item rounded answ-bnt">
                  {message.text}
                </span>
              </div>
            </div>
          </div>
        )
      )}
      <div ref={autofocus}></div>
      {!selectedOption && (
        <>
          {questions[currentQuestionIndex].options &&
            questions[currentQuestionIndex].options.length > 0 &&
            renderOption === true ? (
            <>
              <div className="chater-prof">
                <div className="supporter-dp">
                  <img src={avatar} alt="" />
                </div>
                <div className="text-msg btn-spc sel-option text-center">
                  {questions[currentQuestionIndex].options.map(
                    (option, index) =>
                      questions[currentQuestionIndex].specialcss === 'verticleButton' ? (
                        <div className="mb-1" key={index}>
                          <span
                            className={`d-inline-block action-btn action-btn-label text-message-item  medibutton verticle-button d-block curs_pointer`}
                            onClick={() => handleOptionSelect(option.text)}
                          >
                            {option.text}{option.dummy_text}
                          </span>
                        </div>
                      ) : questions[currentQuestionIndex].id ==
                        "CALL_CLICK" ? (
                        is_online == "online" ? (
                          <a
                            key={index}
                            className="d-inline-block action-btn action-btn-label curs_pointer text-message-item medibutton jobBtn mx-1 text-decoration-none"
                            onClick={() => { handleOptionSelect(option.text) }}
                            href={`tel: ${phoneNumber}`}
                          >
                            <span className='call icon_option_num icon_option_num_a text-white'>{formattedPhoneNumber}</span>
                          </a>
                        ) : (
                          <div>
                            <a
                              key={index}
                              className="d-inline-block action-btn action-btn-label curs_pointer text-message-item medibutton jobBtn mx-1 text-decoration-none"
                              onClick={(event) => {
                                event.preventDefault();
                                handleRedirect(1, "call_offline");
                              }}
                              target="blank"
                            >
                              <span className="call icon_option_num icon_option_num_a text-white">
                                Claim Benefits
                              </span>
                            </a>
                          </div>
                        )
                      ) : (
                        <span
                          key={index}
                          className={`d-inline-block action-btn action-btn-label curs_pointer text-message-item msg01 me-1`}
                          onClick={() => handleOptionSelect(option.text)}
                        >
                          {option.text}
                        </span>
                      )
                  )}
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
      {/* <SimpleModal
        modalBody={modalBody}
        setModalBody={setModalBody}
        ModalViewers={ModalViewers}
        showLoader={showLoader}
        buttonText="Reclamar Beneficios"
      /> */}
    </div>
  );
};

export default ChatBotBubble_UKPI_V6;
