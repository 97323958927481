import React from "react";
import Header from "../../Includes/Layouts/UKPI_V5/Header";
import Footer from "../../Includes/Layouts/UKPI_V5/Footer";
import "../../../assets/UKPI_V5/css/style.scss";
import "../../../assets/UKPI_V5/css/animate.css";
import FormUKPI_V5 from "../../Forms/FormUKPI_V5";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import darrow from "../../../assets/UKPI_V5/img/fc6c95b9-o-arrow.gif";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import FbViewPixel from "../../../Utility/FbViewPixel";

const UKPI_V5 = () => {
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('UKPI_V5','UKPI_V5'); //for dynamic routing

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="UKPI_V5" />
      <FbViewPixel />
      <div className="UKPI_V5" id="UKPI_V5">
        <Header />
        <section className="py-0 py-md-2 SECT ">
          <div className="container p-0">
            <div className="form_part bg-white px-0 px-md-2 py-0 py-md-3">
              <h1 className="d-none d-sm-block d-md-block d-lg-block text-center fw-bold">
              Take This Free 30 Second Online Evaluation To Help{" "}
                <span>Maximize Your Potential Compensation</span>
              </h1>

              <div className="sm-device-txt">
                <h1 className="d-block d-sm-none d-md-none d-lg-none text-center text-white fw-bold">
                Take This Free 30 Second Online Evaluation To Help{" "}
                <span>Maximize Your Potential Compensation</span>
                </h1>
                {/* <h2 className="d-block d-sm-none d-md-none d-lg-none text-center">
                  By answering a few simple questions, we will be able to see if
                  you could be entitled to thousands in compensation for a
                  personal injury including: accidents at work, road traffic
                  accidents, slips trip and falls + more.
                </h2> */}
              </div>

              <p>
                <strong>
                  <em>*Quiz may take a few seconds to load*</em>
                </strong>
              </p>

              <div className="arrow_gif">
                <img src={darrow} alt="" />
              </div>
              <FormUKPI_V5 splitName ={DynamicRouteSplitName} nextPage={DynamicRouteNextPage}/>            
              {/* <div className="text-center safe-text">
                <img
                  src="assets/UKPI_V5/img/ico-safe.png"
                  className="safe-text"
                  alt=""
                />{" "}
                Safe | Secure | Privacy Protected
              </div>
              <div className="form-check p-2 offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
                <label
                  className="form-check-label text-consent text-black fs-6 mt-0"
                  htmlFor="accept"
                >
                  I consent for National Injury Claimline Ltd (The Compensation
                  Experts) to process the above data and (personal injury)
                  health data to contact me in relation to a possible claim. To
                  read more about how we process your personal data, please see
                  our{" "}
                  <a href="/privacy" target="_blank">
                    {" "}
                    Privacy Policy.
                  </a>
                </label>
              </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default UKPI_V5;
