import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import star from "../../../../assets/UKPI_V2/img/stars-4.5.svg";
import info from "../../../../assets/UKPI_V2/img/info.jpg";
import TrustBox from "../../../UI/TrustBox";
const BasicQuestionnaireSix = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active16,setActive16] = useState("");
  const [active17,setActive17] = useState("");

  const nextSlide = async (e) => {
    
    if(e.target.value == 16){
      setActive16("active");
    }else{
      setActive16("");
    }

    if(e.target.value == 17){
      setActive17("active");
    }else{
      setActive17("");
    }
    slideChange(e)
  };

  
  return (
    <>
      <div id="slide6" className={className}>
        <div className="offset-lg-3 col-lg-6 col-md-16 form_cont text-center firstslide">
          <div className="mb-3">
            <label className="fw-bold mb-1">{textHeading}</label>
            <RadioButton
              value="16"
              name="question_6"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Yes"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active16}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="17"
              name="question_6"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="No"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active17}`}
              onClick={nextSlide}
              validation={validation()}
            />

          </div>
          <a name="question_5"  className="back_btn sctop1" onClick={prevSlide}>
              <img src="assets/UKPI_V2/img/arrow.png" alt="" />  
              {' '}Previous </a>
        </div>
        <div className="text-center safe-text">
          <img
            src="assets/UKPI_V2/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
        <div className=" col-lg-12 col-12 trust_pi mb-2 mt-3">
                    <a
                      href="https://www.trustpilot.com/review/the-compensation-experts.co.uk"
                      target="_blank"
                    >
                      <div
                        className="trust-sec-widget"
                      >
                        <TrustBox />
                      </div>
        

                      <div className="row box-a mds-4 "> 
                         <div className="col-7 p-0 trustpilot"> 
                         <img src={star} className="img-star" alt="" />
                          </div>
                         <div className="col-3 p-0 d-flex flex-row align-items-center"> 
                         <div> 
                         <p> 
                          <b className="rate-num px-2"> 4.6</b>
                          </p>
                         </div>
                          <div className="last-trust-img"> 
                          <img
                          src={info}
                          className="info_img"
                          alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                                            Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                        />
                          </div>
                         </div>
                      </div>
                    </a>
                  </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireSix;
