import React from 'react';

const Footer = () => {

  return (
    <>
<section className="footer_text">
      <div className="container">
        <div className="row">
          <div className=" col-lg-12">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-9 col-12 footer_contents">
                <h6>
                  <b>Disclaimer</b>
                </h6>
                <p>We receive payments from the third-party solicitors to whom we refer claims.</p>
                <p>The solicitors we work with operate on a no-win-no-fee basis. Typically, customers pay 25% (inclusive of VAT) of the compensation amount that is recovered, although this is subject to your individual circumstances and the actual fee may be less than this. Should you terminate your agreement outside the 14-day cooling-off period, termination fees may apply.</p>
                <p>Where you may have a criminal injury claim, you are not required to use the services of a claims management company to pursue the claim. It is possible for you to present the claim yourself directly, for free, to the Criminal Injuries Compensation Authority (CICA). You must first have obtained a crime reference number.</p>
                <p>Depending on your circumstances, you may be able to pursue a claim yourself, for free, without using the service of a claims management company, and without incurring a fee by claiming directly with the Motor Insurance Bureau. This may apply where you have been involved in an accident with a driver and intend to claim against an uninsured driver, an untraced driver, accidents in the UK involving a foreign-registered vehicle, or a claim involving a vehicle accident abroad (EEA/Green Card System).</p>
                <p>Depending on your circumstances and the value of your claim, you may be able to pursue a claim yourself, for free, without using the services of a claims management company and without incurring a fee by claiming directly through the Official Injury Claim Portal.</p>
                <p>The Compensation Experts is a trading name of National Injury Claimline Ltd. National Injury Claimline Ltd is authorised and regulated by the Financial Conduct Authority and is a claims management company. Firm Reference Number: 837834.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12 footer_links">
                <h6>
                  <b>Useful Links</b>
                </h6>
                <ul>
                  <li>
                    <a href="/privacy" target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/complaint" target="_blank">Complaint Procedure</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>


    {/* <footer>
      <div className="conainer text-center">
        <div className="footer_logo text-center">
          <img src="assets/UKPI_V4/img/logo1.jpg" alt="" />
        </div>
      </div>
    </footer> */}

    </>
  );
}

export default Footer;