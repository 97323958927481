import { GmailWebhookApi } from "../api/Api";
import { currentdate, currenttime } from "../Utility/CurrentDateTime";
import { AppAdtopiaUPID, AppEnv, AppGmailWebhookError, AppSentryWebhookError } from "../Constants/EnvConstants";
import { Sentry } from '../Utility/Sentry';

export const useErrorWebhook = () => {
  const ErrorWebhookTrigger = (errorMsg) => {
    const message = `
          {
            Service Name : ${errorMsg.serviceName}
            Project ID : ${AppAdtopiaUPID}
            UUID: ${errorMsg.uuid}
            Error Reason : ${errorMsg.errorReason}
            Error Code : ${errorMsg.errorCode}
            Datetime : ${currentdate} ${currenttime}
            Environment : ${AppEnv}
          }
          `;
    if (AppGmailWebhookError == 'true') {
      const response = GmailWebhookApi.post("",
        {
          text: message
        });
    }
    if (AppSentryWebhookError == 'true') {
      Sentry.captureMessage({ "message": message });
    }
  }
  return { ErrorWebhookTrigger }
}