import { useState } from "react";
import { Api } from "../api/Api";

export const useUserClicks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const saveUserClicks = async (uuid, click_type, status) => {
    setIsLoading(true);
    const response = await Api.post("v1/save-user-clicks", {
      uuid,
      click_type,
      status
    });
    setIsLoading(false);
    return response;
  };
  return { saveUserClicks, isLoading };
};
