import React, { useState, useContext } from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { currentdate, currenttime } from "../../../../Constants/Constants";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
//import { useEmailWebhook } from "../../../../Hooks/useEmailWebhook";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";

const ContactDetails = ({
  className,
  validation,
  validationMsg,
  trigger,
  slideChange,
  prevSlide,
  textHeading,
  getValues,
  setError,
  formSubmit,
}) => {
  const { emailValidation, emailValidationErr } = useEmailValidation();
  //const { weHookApiCall } = useEmailWebhook();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [disabled, setDisabled] = useState(false);
  const [loader, setloader] = useState(false);
  //const [webHookState, setWebHookState] = useState(false);

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone,visitorParameters.visitor_parameters.uuid);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Mobile Number",
        });
        return 0;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (emailValidationErr) {
        return 1;
      }
      if (
        typeof getEmailValidation === "undefined" ||
        getEmailValidation === null
      ) {
        return 2;
      }

      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
        return 0;
      } else {
        return 1;
      }
    } else {
      setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email Address",
      });
    }
  };

  const submitForm = async (e) => {
    // setDisabled(true);
    setloader('loader_img');
    const formvalues = getValues();
    const emailValidChk = await trigger("txtEmail");
    const phoneValidChk = await trigger("txtPhone");

    if (emailValidChk && phoneValidChk) {

      if ((await emailValidCheck()) === 2 || emailValidationErr) {
        setloader(false);

        // if(!webHookState) {

        //   const message = `
        // {
        //   Service Name : Email Single Endpoint API
        //   Project ID : NCPI036
        //   UUID : ${visitorParameters.visitor_parameters.uuid}
        //   Error Reason : Validation API Timeout
        //   Datetime : ${currentdate} ${currenttime}
        // }
        // `;
        // const getWebhookRes = await weHookApiCall(message);
        // console.log('api delay');
        // }
        // setWebHookState(true);

        let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!formvalues["txtEmail"].match(mailformat)) {
          setError("txtEmail", {
            type: "manual",
            message: "Please enter valid Email Address",
            shouldFocus: true,
          });
          return false;
        }

        setDisabled(true);

      }

      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        setDisabled(true);
        formSubmit();
      } else {
        setDisabled(false);
        setloader(false);
      }
    } else {
      setDisabled(false);
      setloader(false);
    }
  };

  return (
    <>
      <div id="slide9" className={className}>
        <div className="form_cont offset-lg-3 col-lg-6 col-md-12 col-sm-12 text-center firstslide mt-1 dmy">
          <p className="mb-0 fs-5 fw-bold">{textHeading}</p>
          <div className="input-group mb-0">
            <label className="label_contact w-100 fs-5 fw-bold">Phone</label>
            <Telephone
              name="txtPhone"
              className="form-control"
              placeholder="Phone Number"
              onBlur={phoneValidCheck}
              validation={validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Valid Phone Number",
              })}
            // validationMsg={
            //   validationMsg.txtPhone && validationMsg.txtPhone.message
            // }
            ></Telephone>

            <div className="input-group-append">
              <span className={"input-group-text"}>
                <img
                  src="/assets/UKPI_V2/img/privacy.png"
                  alt=""
                  className="privcy pull-right"
                />
              </span>
            </div>
            {validationMsg.txtPhone && (
              <span className="error_msg " id="phone_err">
                {validationMsg.txtPhone.message}
              </span>
            )}
          </div>
          <div className="input-group mb-3">
            <label className="label_contact w-100 fs-5 fw-bold">Email</label>
            <Email
              name="txtEmail"
              className="form-control"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please Enter Valid Email Address",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                //   message: "Invalid Email Address",
                // },
              })}
            // validationMsg={
            //   validationMsg.txtEmail && validationMsg.txtEmail.message
            // }
            ></Email>
            <i className="tick fa_privcy" style={{ display: "none" }}></i>
            <div className={"input-group-append"}>
              <span className={"input-group-text"}>
                <img
                  src="/assets/UKPI_V2/img/privacy.png"
                  alt=""
                  className={"privcy pull-right"}
                />
              </span>
            </div>
            {validationMsg.txtEmail && (
              <span className="error_msg " id="email_err">
                {validationMsg.txtEmail.message}
              </span>
            )}
          </div>
          {/* <InputButton
            name="formSubmitBtn"
            className={`next-but btn my-bt sctop1 text-white fw-bold rounded ${loader}`}
            value="Get My Free Quote >>"
            btnType="button"
            onClick={submitForm}
            disabled={disabled}
          /> */}
          <InputButton
            name="formSubmitBtn"
            className={`next-but btn my-bt sctop1 text-white fw-bold rounded ${loader}`}
            value="Start My Claim"
            btnType="button"
            onClick={submitForm}
            disabled={disabled}
          />
          <a name="question_1" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/UKPI_V2/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text">
          <img
            src="assets/UKPI_V2/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
        <div className="form-check offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
          <label
            className="form-check-label text-black fs-6 mt-0"
            htmlFor="accept"
          >I consent for National Injury Claimline Ltd (The Compensation Experts) to process the above data and (personal injury) health data to contact me in relation to a possible claim. To read more about how we process your personal data, please see our{" "}
            <a
              href="/privacy"
              target="_blank"
            >
              {" "}
              Privacy Policy.
            </a>
          </label>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;
