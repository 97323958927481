export const data = [
  { label: "Accident At Work", value: 100, optionValue: 1 },
  { label: "Road Traffic Accident", value: 10, optionValue: 4 },
  {
    label: "Accident In a Private Place (Shop, Bar, Restaurant etc.)",
    
    value: 50,
    optionValue: 2,
  },
  {
    label: "Accident in a Public Place (Park, Pavement etc.)",
    value: 10,
    optionValue: 3,
  },
  { label: "Industrial Disease", value: 33, optionValue: 5 },
  { label: "Criminal Injury", value: 25, optionValue: 6 },
  { label: "Other", value: 1, optionValue: 7 },
];

export const getConversionValue = (answer) => {
  const foundItem = data.find((item) => item.label === answer);
  return {
    value: foundItem ? foundItem.value : null,
    optionValue: foundItem ? foundItem.optionValue : null,
  };
};
