import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const usePhoneValidation = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const phoneValidation = async (phone, uuid) => {
    let phoneVal = 1;
    const getValidation = await Api.get(
      "v1/get-phone-validation?phone=" + phone + "&uuid= " + uuid,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'PHONE VALIDATION',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      phoneVal = 0;
      ErrorWebhookTrigger(erroMessage);
    });
    if (phoneVal == 0) {
      let phoneFormat = /^[0-9]*$/i;
      if ((phone.length < 11) || (!phone.match(phoneFormat))) {
        return {
          data: {
            status_code: 0
          }
        }
      } else {
        return {
          data: {
            status_code: 1
          }
        }
      }
    } else {
      return getValidation;
    }
  };
  return { phoneValidation };
};
