import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';
import logo from '../../../../assets/UKPI_V5/img/logo1.jpg';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header className='d-block d-md-block p-2 bg-white'>
         <div className="container text-center">
                <img src={logo} alt="" className="logo_1" />     
          </div>
      </header>
    </>
  );
};

export default Header;