import React, { useState,useContext } from "react";
import RadioButton from "../../../UI/RadioButton";
import Popup from "./Popup";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useVisitorAnswer } from "../../../../Hooks/useVisitorAnswer";
import star from "../../../../assets/UKPI_V3/img/stars-4.5.svg";
import info from "../../../../assets/UKPI_V3/img/info.jpg";
import TrustBox from "../../../UI/TrustBox";

const BasicQuestionnaireThree = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active10,setActive10] = useState("");
  const [active11,setActive11] = useState("");
  const [shwPopup, setshwPopup] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveAnswer } = useVisitorAnswer();

  const nextSlide = async (e) => {
    
    if(e.target.value == 11){
      setActive11("active");
    }else{
      setActive11("");
    }

    if(e.target.value == 10){
      const saveAns = await saveAnswer(
        3,
        e.target.value,
        visitorParameters.visitor_parameters,
    );
      setActive10("active");
      setshwPopup(true);
      return false;
    }else{
      setActive10("");
    }

    slideChange(e)
  };

  
  return (
    <>
      <Popup
        show={shwPopup}
        setshwPopup={setshwPopup}
        message="Sorry, If you have received solicitor assistance, we can't help you"
      />
      <div id="slide2" className={className}>
        <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center firstslide">
          <div className="mb-3">
            <label className="fw-bold mb-1">{textHeading}</label>

            <RadioButton
              value="10"
              name="question_3"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Yes"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active10}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="11"
              name="question_3"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="No"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active11}`}
              onClick={nextSlide}
              validation={validation()}
            />

          </div>
          <a name="question_0"  className="back_btn sctop1" onClick={prevSlide}>
              <img src="assets/UKPI_V2/img/arrow.png" alt="" />  
              {' '}Previous </a>
        </div>
        <div className="text-center safe-text">
          <img
            src="assets/UKPI_V2/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
        <div className=" col-lg-12 col-12 trust_pi mb-2 mt-3">
                    <a
                      href="https://www.trustpilot.com/review/the-compensation-experts.co.uk"
                      target="_blank"
                    >
                      <div
                        className="trust-sec-widget"
                      >
                        <TrustBox />
                      </div>
        

                      <div className="row box-a mds-4 "> 
                         <div className="col-7 p-0 trustpilot"> 
                         <img src={star} className="img-star" alt="" />
                          </div>
                         <div className="col-3 p-0 d-flex flex-row align-items-center"> 
                         <div> 
                         <p> 
                          <b className="rate-num px-2"> 4.6</b>
                          </p>
                         </div>
                          <div className="last-trust-img"> 
                          <img
                          src={info}
                          className="info_img"
                          alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                                            Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                        />
                          </div>
                         </div>
                      </div>
                    </a>
                  </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireThree;
